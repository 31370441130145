﻿{
    "advanced": {
        "action_list": "Aktionsliste",
        "helpers": "Helfer",
        "hide": "Ausblenden",
        "loading_workers": "Arbeiter werden geladen",
        "run": "Ausführen",
        "show": "Anzeigen",
        "sim_options": "Simulationsoptionen",
        "substat_helper": "Bonusattributhelfer",
        "workers_available": "Verfügbare Arbeiter: "
    },
    "character": {
        "artifact_stats": "Artefaktattribute",
        "c_post": "",
        "c_pre": "C",
        "lvl": "Stufe",
        "talents": "Talente"
    },
    "characteredit": {
        "add_set_bonus": "Setbonus hinzufügen",
        "anemo": "Anemo%",
        "artifact_stats_main": "Artefaktattribute (Haupt- + Bonusattribute)",
        "ascension": "Aufstiegsstufe",
        "atk_atk": "ANG/ANG%",
        "attack": "Standardangriff",
        "burst": "Spezialfähigkeit",
        "cd": "KSCH",
        "change": "Wechseln",
        "character": "Figur",
        "cons": "Sternbild",
        "cr": "KT",
        "cryo": "Kryo%",
        "def_def": "VTD/VTD%",
        "electro": "Elektro%",
        "em": "Elementarkunde",
        "er": "Aufladerate",
        "geo": "Geo%",
        "heal": "Heilungsbonus%",
        "hp_hp": "LP/LP%",
        "hydro": "Hydro%",
        "level": "Stufe",
        "phy": "Physisch%",
        "pyro": "Pyro%",
        "refine": "Verfeinerungsgrad",
        "set_bonus": "Setbonus:",
        "skill": "Elementarfähigkeit",
        "weapons_and_artifacts": "Waffen und Artefakte"
    },
    "components": {
        "close": "Schließen",
        "currently_loaded_workers": "Aktuell geladene Arbeiter: ",
        "no_characters": "Keine Figuren",
        "result_post": "ms mit durchschnittlichem DPS: ",
        "result_pre": "Simulation abgeschlossen in ",
        "running_simulation": "Führe Simulation aus:",
        "see_results_in": "Ergebnisse im Betrachter ansehen",
        "set": "Speichern",
        "simulation_exited_with": "Simulation mit Fehler beendet: ",
        "workers_post": " Arbeitern aus",
        "workers_pre": "Führe Simulation mit "
    },
    "dash": {
        "about": "Über uns",
        "advanced_mode": "Fortgeschrittener Modus",
        "contribute": "Beitragen",
        "desktop_tool": "Desktop-Tool",
        "documentation": "Dokumentation",
        "is_a_team": "ist ein Team DPS Simulator für Genshin Impact. Beginnen Sie mit der Auswahl einer der folgenden Optionen!",
        "simulator": "Simulator",
        "teams_db": "Teamdatenbank",
        "viewer": "Betrachter"
    },
    "db": {
        "author": "Autor: ",
        "average_dps_per": "Durchschnittlicher DPS pro Ziel: ",
        "c_post": "",
        "c_pre": "C",
        "cancel": "Abbrechen",
        "character": "Figur",
        "continue": "Fortfahren",
        "description": "Beschreibung:",
        "details": "Details",
        "er": "Aufladerate: ",
        "error_loading_database": "Fehler beim Laden der Datenbank. Keine Daten gefunden.",
        "filters": "Filter:",
        "hash": "Hash: ",
        "load_in_simulator": "Im Simulator laden",
        "loading": "Wird geladen...",
        "number_of_targets": "Anzahl der Ziele: ",
        "r": " Verfeinerungsgrad",
        "show_in_viewer": "Im Betrachter anzeigen",
        "total_dps": "Gesamter DPS: ",
        "type_to_search": "Zum Suchen tippen...",
        "weapon": "Waffe"
    },
    "elements": {
        "none": "Elementlos",
        "anemo": "Anemo",
        "geo": "Geo",
        "pyro": "Pyro",
        "cryo": "Kryo",
        "hydro": "Hydro",
        "electro": "Elektro",
        "dendro": "Dendro"
    },
    "footer": {
        "gcsim_is_not": "gcsim ist nicht mit miHoYo verbunden. Genshin Impact, Spielinhalte und Spielmaterialien sind Markenzeichen und Copyright von miHoYo."
    },
    "importer": {
        "import_success": "GOOD-Daten erfolgreich importiert"
    },
    "links": {
        "error_loading_database": "Fehler beim Laden der Datenbank. Keine Daten gefunden."
    },
    "nav": {
        "about": "Über uns",
        "advanced": "Fortgeschritten",
        "chinese": "中文",
        "japanese": "日本語",
        "english": "English",
        "spanish": "Español",
        "russian": "Русский",
        "german": "Deutsch",
        "language": "Sprache:",
        "simulator": "Simulator",
        "teams_db": "Teamdatenbank",
        "viewer": "Betrachter"
    },
    "sim": {
        "loading_simulator_please": "Simulator wird geladen. Bitte warten."
    },
    "simple": {
        "action_list": "Aktionsliste",
        "add_character": "Figur hinzufügen",
        "discord_post": ", falls Sie Ideen brauchen.",
        "discord_pre": "Geben Sie die zu verwendende Aktionsliste in die nachfolgende Textbox ein. Wir haben eine große Sammlung an Aktionslisten, welche von Nutzern eingereicht wurden, auf unserem ",
        "documentation": "Dokumentation",
        "documentation_post": " an, welche ein einfaches Tutorial zum Schreiben von sequentiellen Listen enthält (vorher bekannt als calc Modus).",
        "documentation_pre": "Ansonsten, schauen Sie sich unsere ",
        "done": "Fertig",
        "exit_edit": "Editor verlassen",
        "give_me_back": "Gib mir meine Tooltips zurück!",
        "hide": "Ausblenden",
        "hide_all_tips": "Alle Tipps ausblenden",
        "loading_workers": "Arbeiter werden geladen",
        "run": "Ausführen",
        "show": "Anzeigen",
        "sim_options": "Simulationsoptionen",
        "start_by_adding": "Beginnen Sie durch das Hinzufügen von Teammitgliedern",
        "team": "Team",
        "video": "Video",
        "video_post": " an um anzufangen.",
        "video_pre": "Fügen Sie Charaktere zu ihrem Team hinzu um zu beginnen. Schauen Sie sich dieses ",
        "workers_available": "Verfügbare Arbeiter: "
    },
    "src": {
        "this_page_is": "Diese Seite ist noch nicht implementiert. Bleiben Sie dran!"
    },
    "viewer": {
        "ability_usage_count": "Anzahl an eingesetzten Fähigkeiten (Durchschnitt)",
        "api_key_supporters": "API-Schlüssel (nur für Unterstützer)",
        "attack": "Standardangriff",
        "average_character_field": "Durchschnittliche Feldzeit pro Figur",
        "average_dps_by": "Durchschnittlicher DPS pro Figur",
        "avg_dps": "Durchschn. DPS",
        "built_on": ", gebaut am: ",
        "cancel": "Abbrechen",
        "cd": "KSCH",
        "clear": "Ausschalten",
        "close": "Schließen",
        "combined": "Insgesamt",
        "config": "Konfiguration",
        "continue": "Fortfahren",
        "copied_to_clipboard": "In die Zwischenablage kopiert",
        "copy": "Kopieren",
        "copy_link": "Link kopieren",
        "cr": "KT",
        "create_a_shareable": "Teilbaren Link erstellen",
        "damage_dealt_over": "Über die Zeit angerichteter Schaden",
        "debug": "Debug",
        "debug_settings": "Debug-Einstellungen",
        "defaults": "Defaults",
        "defense": "Verteidigung",
        "details": "Details",
        "dps_by_ability": "DPS pro Fähigkeit (Durchschnitt)",
        "em": "Elementarkunde",
        "er": "Aufladerate",
        "error_copying_not": "Fehler beim Kopieren :( Irgendwas ist schief gelaufen",
        "error_encountered": "Es ist ein Fehler aufgetreten: ",
        "git_hash": "git hash: ",
        "hp": "LP",
        "level": "Stufe",
        "link_is_permanent": "Der Link ist permanent. ",
        "link_post": "Die Ergebnisse können unter diesem Link eingesehen werden: ",
        "link_pre": "Der Upload war erfolgreich. ",
        "link_will_expire": "Dieser Link wird in 7 Tagen ablaufen. ",
        "load_this_configuration": "Lade diese Konfiguration im Fortgeschrittenen Modus.",
        "log_options": "Log-Optionen",
        "make_link_permanent": "Link permament machen?",
        "note_that_by": "Bitte beachten Sie, dass teilbare Links standardmäßig nur für 7 Tage gültig sind. Das ist nötig, um den Serverspeicher auf einem angemessenen Niveau zu halten. Mitwirkende und Unterstützer über Ko-Fi können einen geheimen Schlüssel eingeben, um ihre Links permament zu machen.",
        "particles_count": "Partikelanzahl",
        "please_note_that": "Bitte beachten Sie: Es soll sich niemand deswegen dazu gezwungen fühlen Geld zu spenden. Es handelt sich hierbei um einen einfachen Weg die Menge an geteilten Daten zu deckeln und gleichzeitig soll es ein kleines Dankeschön an diejenigen sein, welche dieses Projekt finanziell oder durch ihr Mitwirken unterstützt haben. Falls Sie keinen Zugangsschlüssel haben, dann schauen Sie doch mal auf unserem Discord vorbei und bitten Sie jemand anderes darum einen Link für Sie zu erstellen.",
        "reactions_triggered": "Ausgelöste Reaktionen",
        "refinement": "Verfeinerungsgrad",
        "sec_post": "s an Kampf simuliert (",
        "sec_pre": "Es wurden ",
        "send_to_simulator": "Zum Simulator senden",
        "share": "Teilen",
        "substats": "Bonusattribute",
        "summary": "Zusammenfassung",
        "target": "Ziel",
        "this_will_overwrite": "Dies wird die aktuelle Konfiguration überschreiben. Sind Sie sich sicher, dass Sie fortfahren möchten?",
        "time_post": "s zum Ausführen).",
        "time_pre": " Iterationen benötigten ",
        "unknown": "unbekannt",
        "upload": "Hochladen",
        "upload_failed": "Der Upload ist fehlgeschlagen."
    },
    "viewerdashboard": {
        "drag_n_drop": "JSON-Datei (.gz) aus gcsim per Drag-and-drop hochladen oder per Klick Dateien auswählen",
        "drop_the_file": "Legen Sie die Datei hier ab...",
        "error_retrieving_specified": "Fehler beim Abrufen der angegebenen URL",
        "or_select_from": "Oder wählen Sie eine der folgenden Dateien aus, die Sie vorher geöffnet haben (wird beim Neuladen der Seite gelöscht):",
        "upload_a_file": "Datei hochladen",
        "url_does_not": "URL enthält keine gültige JSON-Datei (.gz)"
    }
}