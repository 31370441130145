{
    "advanced": {
        "action_list": "アクションリスト",
        "helpers": "補助ツール",
        "hide": "畳む",
        "loading_workers": "ワーカーを読み込み中",
        "run": "実行",
        "show": "展開",
        "sim_options": "シミュレーター設定",
        "substat_helper": "サブオプション設定ツール",
        "workers_available": "使用可能なワーカー数: "
    },
    "character": {
        "artifact_stats": "聖遺物ステータス",
        "c_post": "凸",
        "c_pre": "星座",
        "lvl": "レベル",
        "talents": "天賦"
    },
    "characteredit": {
        "add_set_bonus": "セット効果を追加",
        "anemo": "風ダメージ",
        "artifact_stats_main": "聖遺物ステータス (メイン + サブ)",
        "ascension": "突破",
        "atk_atk": "攻撃実数/攻撃%",
        "attack": "通常攻撃",
        "burst": "元素爆発",
        "cd": "会心ダメージ",
        "change": "変更",
        "character": "キャラクター",
        "cons": "星座",
        "cr": "会心率",
        "cryo": "氷ダメージ",
        "def_def": "防御力/防御力%",
        "electro": "雷ダメージ",
        "em": "元素熟知",
        "er": "元素チャージ効率",
        "geo": "岩ダメージ",
        "heal": "治癒効果バフ",
        "hp_hp": "HP実数/HP%",
        "hydro": "水元素ダメージ",
        "level": "レベル",
        "phy": "物理ダメージ",
        "pyro": "炎ダメージ",
        "refine": "精錬",
        "set_bonus": "セット効果:",
        "skill": "スキル",
        "weapons_and_artifacts": "武器と聖遺物"
    },
    "components": {
        "close": "閉じる",
        "currently_loaded_workers": "現在読み込まれているワーカー数: ",
        "no_characters": "キャラクターがありません",
        "result_post": "ミリ秒 / DPS: ",
        "result_pre": "シミュレーション完了 - 経過時間: ",
        "running_simulation": "シミュレーション実行中:",
        "see_results_in": "結果を閲覧モードで確認",
        "set": "設定",
        "simulation_exited_with": "エラー発生によりシミレーションは中断されました: ",
        "workers_post": "でシミュレーションを実行中...",
        "workers_pre": "ワーカー数："
    },
    "dash": {
        "about": "このツールについて",
        "advanced_mode": "詳細モード(上級者向け)",
        "contribute": "協力する",
        "desktop_tool": "デスクトップ版",
        "documentation": "資料",
        "is_a_team": "は原神のための編成DPSシミュレーターです。 以下のボタンから各モードへ移れます!",
        "simulator": "シミュレーター",
        "teams_db": "編成データベース",
        "viewer": "閲覧モード"
    },
    "db": {
        "author": "作成者: ",
        "average_dps_per": "ターゲット1体あたりの平均DPS: ",
        "c_post": "凸",
        "c_pre": "",
        "cancel": "キャンセル",
        "character": "キャラクター",
        "continue": "続ける",
        "description": "説明:",
        "details": "詳細",
        "er": "元素チャージ効率: ",
        "error_loading_database": "データベースを読み込み中にエラーが発生しました: データが存在しません",
        "filters": "絞り込み:",
        "hash": "ハッシュ: ",
        "load_in_simulator": "シミュレーターに読み込み",
        "loading": "読み込み中 ...",
        "number_of_targets": "ターゲット数: ",
        "r": " 精錬",
        "show_in_viewer": "閲覧モードに読み込み",
        "total_dps": "合計DPS: ",
        "type_to_search": "ここに入力して検索...",
        "weapon": "武器"
    },
    "footer": {
        "gcsim_is_not": "このウェブサイトは個人的な計算を目的としており、gcsim, miHoYoとは関係ありません。原神のゲームコンテンツや素材の商標および著作権はmiHoYoに帰属します。 日本語翻訳作成: からしな_（Karashina#8663）"
    },
    "links": {
        "error_loading_database": "データベースを読み込み中にエラーが発生しました: データが存在しません"
    },
    "nav": {
        "about": "このツールについて",
        "advanced": "詳細モード",
        "chinese": "中文",
        "japanese": "日本語",
        "english": "English",
        "spanish": "Español",
        "russian": "Русский",
        "german": "Deutsch",
        "language": "言語設定:",
        "simulator": "シミュレーター",
        "teams_db": "編成データベース",
        "viewer": "閲覧モード"
    },
    "sim": {
        "loading_simulator_please": "シミュレーター読み込み中 - しばらくお待ちください。"
    },
    "simple": {
        "action_list": "アクションリスト",
        "add_character": "キャラクター追加",
        "discord_post": " に多くの記述例がありますのでご参照ください。",
        "discord_pre": "下のテキストボックスにシミュレーションへ追加したい行動や要素を記述します。何を書けばいいかわからない方は",
        "documentation": "資料",
        "documentation_post": "もありますのでご活用ください。",
        "documentation_pre": "また、連続モード（旧:calc mode）の使い方や使用できる要素をまとめた",
        "done": "完了",
        "exit_edit": "編集を終了する",
        "give_me_back": "チュートリアルを再表示",
        "hide": "隠す",
        "hide_all_tips": "すべてのチュートリアルを隠す",
        "loading_workers": "ワーカーを読み込み中",
        "run": "実行",
        "show": "表示",
        "sim_options": "シミュレーター",
        "start_by_adding": "編成にキャラクターを追加",
        "team": "編成",
        "video": "動画",
        "video_post": "をチェックしてください！",
        "video_pre": "編成にキャラクターを追加して開始しましょう！使い方はこの",
        "workers_available": "使用可能なワーカー数: "
    },
    "src": {
        "this_page_is": "このページはまだ実装されていません。いましばらくお待ちください！"
    },
    "viewer": {
        "ability_usage_count": "能力使用回数 (平均値)",
        "api_key_supporters": "APIキー (支援者限定)",
        "attack": "通常攻撃",
        "average_character_field": "平均出場時間",
        "average_dps_by": "キャラクターごとの平均DPS",
        "avg_dps": "平均DPS",
        "built_on": ", 作成日時: ",
        "cancel": "キャンセル",
        "cd": "会心ダメージ",
        "clear": "クリア",
        "close": "閉じる",
        "combined": "合計",
        "config": "設定",
        "continue": "続ける",
        "copied_to_clipboard": "クリップボードにコピーしました",
        "copy": "コピー",
        "copy_link": "URLをコピー",
        "cr": "会心率",
        "create_a_shareable": "共有可能なリンクを取得",
        "damage_dealt_over": "時間経過による与ダメージの推移",
        "debug": "デバッグ",
        "debug_settings": "デバッグ設定",
        "defaults": "でフォルト",
        "defense": "防御力",
        "details": "詳細",
        "dps_by_ability": "天賦ごとのDPS (平均)",
        "em": "元素熟知",
        "er": "元素チャージ効率",
        "error_copying_not": "コピー失敗エラー :( 原因不明",
        "error_encountered": "エラー発生: ",
        "git_hash": "git hash: ",
        "hp": "HP",
        "level": "レベル",
        "link_is_permanent": "リンクは失効しません。 ",
        "link_post": "結果はこちらで確認可能です: ",
        "link_pre": "アップロード完了。",
        "link_will_expire": "リンクは7日間で失効します。 ",
        "load_this_configuration": "この設定を詳細モードで読み込みます。",
        "log_options": "ログ設定",
        "make_link_permanent": "リンクを永続化する",
        "note_that_by": "通常共有可能なリンクは7日間で失効します。これはサーバーの容量を適切に管理するためです。なおKo-Fiにて支援していただいた方はリンクを失効しないように設定できるAPIキーを取得できます。",
        "particles_count": "粒子の生成数",
        "please_note_that": "なおこの仕様は寄付を募るためのものではありません。制限されたサーバーの容量を適切に管理するための施策で、このプロジェクトを金銭的または寄付によってサポートしてくれた人々にささやかな感謝の意を示すものです。なおアクセスキーは私たちのDiscordサーバーにアクセスして取得することができます。",
        "reactions_triggered": "トリガーされた元素反応",
        "refinement": "精錬",
        "sec_post": "秒間の戦闘をシミュレート (",
        "sec_pre": "",
        "send_to_simulator": "シミュレーターに送る",
        "share": "共有",
        "substats": "サブオプション",
        "summary": "概要",
        "target": "ターゲット",
        "this_will_overwrite": "この操作は既存のシミュレーションデータを置き換えます。続行しますか？",
        "time_post": "秒かけて計算完了)",
        "time_pre": " 回の反復を ",
        "unknown": "不明",
        "upload": "アップロード",
        "upload_failed": "アップロード失敗"
    },
    "viewerdashboard": {
        "drag_n_drop": "読み込むにはzip化されたgcsimのJSONファイルをドラッグアンドドロップするかボタンをクリックしてアップロードしてください。",
        "drop_the_file": "ここにファイルをドロップ ...",
        "error_retrieving_specified": "指定されたURLを取得できませんでした",
        "or_select_from": "こちらから履歴を読み込む事もできます。（注意:再読み込みすると削除されます）",
        "upload_a_file": "ファイルをアップロード",
        "url_does_not": "このURLには読み込み可能なファイルが含まれていません。"
    }
}