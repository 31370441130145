{
  "English": {
    "artifact_names": {
      "archaicpetra": "Archaic Petra",
      "berserker": "Berserker",
      "blizzardstrayer": "Blizzard Strayer",
      "bloodstainedchivalry": "Bloodstained Chivalry",
      "braveheart": "Brave Heart",
      "crimsonwitchofflames": "Crimson Witch of Flames",
      "defenderswill": "Defender's Will",
      "echoesofanoffering": "Echoes of an Offering",
      "emblemofseveredfate": "Emblem of Severed Fate",
      "gambler": "Gambler",
      "gladiatorsfinale": "Gladiator's Finale",
      "heartofdepth": "Heart of Depth",
      "huskofopulentdreams": "Husk of Opulent Dreams",
      "instructor": "Instructor",
      "lavawalker": "Lavawalker",
      "maidenbeloved": "Maiden Beloved",
      "martialartist": "Martial Artist",
      "noblesseoblige": "Noblesse Oblige",
      "oceanhuedclam": "Ocean-Hued Clam",
      "paleflame": "Pale Flame",
      "prayersfordestiny": "Prayers for Destiny",
      "prayersforillumination": "Prayers for Illumination",
      "prayersforwisdom": "Prayers for Wisdom",
      "prayerstospringtime": "Prayers to Springtime",
      "resolutionofsojourner": "Resolution of Sojourner",
      "retracingbolide": "Retracing Bolide",
      "scholar": "Scholar",
      "shimenawasreminiscence": "Shimenawa's Reminiscence",
      "tenacityofthemillelith": "Tenacity of the Millelith",
      "theexile": "The Exile",
      "thunderingfury": "Thundering Fury",
      "thundersoother": "Thundersoother",
      "tinymiracle": "Tiny Miracle",
      "vermillionhereafter": "Vermillion Hereafter",
      "viridescentvenerer": "Viridescent Venerer",
      "wandererstroupe": "Wanderer's Troupe"
    },
    "character_names": {
      "aether": "Aether",
      "lumine": "Lumine",
      "albedo": "Albedo",
      "aloy": "Aloy",
      "amber": "Amber",
      "barbara": "Barbara",
      "beidou": "Beidou",
      "bennett": "Bennett",
      "chongyun": "Chongyun",
      "diluc": "Diluc",
      "diona": "Diona",
      "eula": "Eula",
      "fischl": "Fischl",
      "ganyu": "Ganyu",
      "hutao": "Hu Tao",
      "jean": "Jean",
      "kazuha": "Kaedehara Kazuha",
      "kaeya": "Kaeya",
      "ayaka": "Kamisato Ayaka",
      "keqing": "Keqing",
      "klee": "Klee",
      "sara": "Kujou Sara",
      "lisa": "Lisa",
      "mona": "Mona",
      "ningguang": "Ningguang",
      "noelle": "Noelle",
      "qiqi": "Qiqi",
      "raiden": "Raiden Shogun",
      "razor": "Razor",
      "rosaria": "Rosaria",
      "kokomi": "Sangonomiya Kokomi",
      "sayu": "Sayu",
      "sucrose": "Sucrose",
      "tartaglia": "Tartaglia",
      "thoma": "Thoma",
      "venti": "Venti",
      "xiangling": "Xiangling",
      "xiao": "Xiao",
      "xingqiu": "Xingqiu",
      "xinyan": "Xinyan",
      "yanfei": "Yanfei",
      "yoimiya": "Yoimiya",
      "zhongli": "Zhongli",
      "gorou": "Gorou",
      "itto": "Arataki Itto",
      "shenhe": "Shenhe",
      "yunjin": "Yun Jin",
      "yaemiko": "Yae Miko",
      "ayato": "Kamisato Ayato",
      "yelan": "Yelan",
      "travelerelectro": "Traveler (Electro)",
      "traveleranemo": "Traveler (Anemo)",
      "travelergeo": "Traveler (Geo)"
    },
    "weapon_names": {
      "akuoumaru": "Akuoumaru",
      "alleyhunter": "Alley Hunter",
      "amenomakageuchi": "Amenoma Kageuchi",
      "amosbow": "Amos' Bow",
      "apprenticesnotes": "Apprentice's Notes",
      "aquasimulacra": "Aqua Simulacra",
      "aquilafavonia": "Aquila Favonia",
      "beginnersprotector": "Beginner's Protector",
      "blackcliffagate": "Blackcliff Agate",
      "blackclifflongsword": "Blackcliff Longsword",
      "blackcliffpole": "Blackcliff Pole",
      "blackcliffslasher": "Blackcliff Slasher",
      "blackcliffwarbow": "Blackcliff Warbow",
      "blacktassel": "Black Tassel",
      "bloodtaintedgreatsword": "Bloodtainted Greatsword",
      "calamityqueller": "Calamity Queller",
      "cinnabarspindle": "Cinnabar Spindle",
      "compoundbow": "Compound Bow",
      "coolsteel": "Cool Steel",
      "crescentpike": "Crescent Pike",
      "darkironsword": "Dark Iron Sword",
      "deathmatch": "Deathmatch",
      "debateclub": "Debate Club",
      "dodocotales": "Dodoco Tales",
      "dragonsbane": "Dragon's Bane",
      "dragonspinespear": "Dragonspine Spear",
      "dullblade": "Dull Blade",
      "elegyfortheend": "Elegy for the End",
      "emeraldorb": "Emerald Orb",
      "engulfinglightning": "Engulfing Lightning",
      "everlastingmoonglow": "Everlasting Moonglow",
      "eyeofperception": "Eye of Perception",
      "fadingtwilight": "Fading Twilight",
      "favoniuscodex": "Favonius Codex",
      "favoniusgreatsword": "Favonius Greatsword",
      "favoniuslance": "Favonius Lance",
      "favoniussword": "Favonius Sword",
      "favoniuswarbow": "Favonius Warbow",
      "ferrousshadow": "Ferrous Shadow",
      "festeringdesire": "Festering Desire",
      "filletblade": "Fillet Blade",
      "freedomsworn": "Freedom-Sworn",
      "frostbearer": "Frostbearer",
      "hakushinring": "Hakushin Ring",
      "halberd": "Halberd",
      "hamayumi": "Hamayumi",
      "harangeppakufutsu": "Haran Geppaku Futsu",
      "harbingerofdawn": "Harbinger of Dawn",
      "huntersbow": "Hunter's Bow",
      "ironpoint": "Iron Point",
      "ironsting": "Iron Sting",
      "kagurasverity": "Kagura's Verity",
      "katsuragikirinagamasa": "Katsuragikiri Nagamasa",
      "kitaincrossspear": "Kitain Cross Spear",
      "lionsroar": "Lion's Roar",
      "lithicblade": "Lithic Blade",
      "lithicspear": "Lithic Spear",
      "lostprayertothesacredwinds": "Lost Prayer to the Sacred Winds",
      "luxurioussealord": "Luxurious Sea-Lord",
      "magicguide": "Magic Guide",
      "mappamare": "Mappa Mare",
      "memoryofdust": "Memory of Dust",
      "messenger": "Messenger",
      "mistsplitterreforged": "Mistsplitter Reforged",
      "mitternachtswaltz": "Mitternachts Waltz",
      "mouunsmoon": "Mouun's Moon",
      "oathsworneye": "Oathsworn Eye",
      "oldmercspal": "Old Merc's Pal",
      "otherworldlystory": "Otherworldly Story",
      "pocketgrimoire": "Pocket Grimoire",
      "polarstar": "Polar Star",
      "predator": "Predator",
      "primordialjadecutter": "Primordial Jade Cutter",
      "primordialjadewingedspear": "Primordial Jade Winged-Spear",
      "prototypeamber": "Prototype Amber",
      "prototypearchaic": "Prototype Archaic",
      "prototypecrescent": "Prototype Crescent",
      "prototyperancour": "Prototype Rancour",
      "prototypestarglitter": "Prototype Starglitter",
      "rainslasher": "Rainslasher",
      "ravenbow": "Raven Bow",
      "recurvebow": "Recurve Bow",
      "redhornstonethresher": "Redhorn Stonethresher",
      "royalbow": "Royal Bow",
      "royalgreatsword": "Royal Greatsword",
      "royalgrimoire": "Royal Grimoire",
      "royallongsword": "Royal Longsword",
      "royalspear": "Royal Spear",
      "rust": "Rust",
      "sacrificialbow": "Sacrificial Bow",
      "sacrificialfragments": "Sacrificial Fragments",
      "sacrificialgreatsword": "Sacrificial Greatsword",
      "sacrificialsword": "Sacrificial Sword",
      "seasonedhuntersbow": "Seasoned Hunter's Bow",
      "serpentspine": "Serpent Spine",
      "sharpshootersoath": "Sharpshooter's Oath",
      "silversword": "Silver Sword",
      "skyridergreatsword": "Skyrider Greatsword",
      "skyridersword": "Skyrider Sword",
      "skywardatlas": "Skyward Atlas",
      "skywardblade": "Skyward Blade",
      "skywardharp": "Skyward Harp",
      "skywardpride": "Skyward Pride",
      "skywardspine": "Skyward Spine",
      "slingshot": "Slingshot",
      "snowtombedstarsilver": "Snow-Tombed Starsilver",
      "solarpearl": "Solar Pearl",
      "songofbrokenpines": "Song of Broken Pines",
      "staffofhoma": "Staff of Homa",
      "summitshaper": "Summit Shaper",
      "swordofdescension": "Sword of Descension",
      "thealleyflash": "The Alley Flash",
      "thebell": "The Bell",
      "theblacksword": "The Black Sword",
      "thecatch": "\"The Catch\"",
      "theflute": "The Flute",
      "thestringless": "The Stringless",
      "theunforged": "The Unforged",
      "theviridescenthunt": "The Viridescent Hunt",
      "thewidsith": "The Widsith",
      "thrillingtalesofdragonslayers": "Thrilling Tales of Dragon Slayers",
      "thunderingpulse": "Thundering Pulse",
      "travelershandysword": "Traveler's Handy Sword",
      "twinnephrite": "Twin Nephrite",
      "vortexvanquisher": "Vortex Vanquisher",
      "wastergreatsword": "Waster Greatsword",
      "wavebreakersfin": "Wavebreaker's Fin",
      "whiteblind": "Whiteblind",
      "whiteirongreatsword": "White Iron Greatsword",
      "whitetassel": "White Tassel",
      "windblumeode": "Windblume Ode",
      "wineandsong": "Wine and Song",
      "wolfsgravestone": "Wolf's Gravestone"
    }
  },
  "Chinese": {
    "artifact_names": {
      "archaicpetra": "悠古的磐岩",
      "berserker": "战狂",
      "blizzardstrayer": "冰风迷途的勇士",
      "bloodstainedchivalry": "染血的骑士道",
      "braveheart": "勇士之心",
      "crimsonwitchofflames": "炽烈的炎之魔女",
      "defenderswill": "守护之心",
      "echoesofanoffering": "来歆余响",
      "emblemofseveredfate": "绝缘之旗印",
      "gambler": "赌徒",
      "gladiatorsfinale": "角斗士的终幕礼",
      "heartofdepth": "沉沦之心",
      "huskofopulentdreams": "华馆梦醒形骸记",
      "instructor": "教官",
      "lavawalker": "渡过烈火的贤人",
      "maidenbeloved": "被怜爱的少女",
      "martialartist": "武人",
      "noblesseoblige": "昔日宗室之仪",
      "oceanhuedclam": "海染砗磲",
      "paleflame": "苍白之火",
      "prayersfordestiny": "祭水之人",
      "prayersforillumination": "祭火之人",
      "prayersforwisdom": "祭雷之人",
      "prayerstospringtime": "祭冰之人",
      "resolutionofsojourner": "行者之心",
      "retracingbolide": "逆飞的流星",
      "scholar": "学士",
      "shimenawasreminiscence": "追忆之注连",
      "tenacityofthemillelith": "千岩牢固",
      "theexile": "流放者",
      "thunderingfury": "如雷的盛怒",
      "thundersoother": "平息鸣雷的尊者",
      "tinymiracle": "奇迹",
      "vermillionhereafter": "辰砂往生录",
      "viridescentvenerer": "翠绿之影",
      "wandererstroupe": "流浪大地的乐团"
    },
    "character_names": {
      "aether": "空",
      "lumine": "荧",
      "albedo": "阿贝多",
      "aloy": "埃洛伊",
      "amber": "安柏",
      "barbara": "芭芭拉",
      "beidou": "北斗",
      "bennett": "班尼特",
      "chongyun": "重云",
      "diluc": "迪卢克",
      "diona": "迪奥娜",
      "eula": "优菈",
      "fischl": "菲谢尔",
      "ganyu": "甘雨",
      "hutao": "胡桃",
      "jean": "琴",
      "kazuha": "枫原万叶",
      "kaeya": "凯亚",
      "ayaka": "神里绫华",
      "keqing": "刻晴",
      "klee": "可莉",
      "sara": "九条裟罗",
      "lisa": "丽莎",
      "mona": "莫娜",
      "ningguang": "凝光",
      "noelle": "诺艾尔",
      "qiqi": "七七",
      "raiden": "雷电将军",
      "razor": "雷泽",
      "rosaria": "罗莎莉亚",
      "kokomi": "珊瑚宫心海",
      "sayu": "早柚",
      "sucrose": "砂糖",
      "tartaglia": "达达利亚",
      "thoma": "托马",
      "venti": "温迪",
      "xiangling": "香菱",
      "xiao": "魈",
      "xingqiu": "行秋",
      "xinyan": "辛焱",
      "yanfei": "烟绯",
      "yoimiya": "宵宫",
      "zhongli": "钟离",
      "gorou": "五郎",
      "itto": "荒泷一斗",
      "shenhe": "申鹤",
      "yunjin": "云堇",
      "yaemiko": "八重神子",
      "ayato": "神里绫人",
      "yelan": "夜兰",
      "travelerelectro": "旅行者 (雷元素)",
      "traveleranemo": "旅行者 (风元素)",
      "travelergeo": "旅行者 (岩元素)"
    },
    "weapon_names": {
      "akuoumaru": "恶王丸",
      "alleyhunter": "暗巷猎手",
      "amenomakageuchi": "天目影打刀",
      "amosbow": "阿莫斯之弓",
      "apprenticesnotes": "学徒笔记",
      "aquasimulacra": "若水",
      "aquilafavonia": "风鹰剑",
      "beginnersprotector": "新手长枪",
      "blackcliffagate": "黑岩绯玉",
      "blackclifflongsword": "黑岩长剑",
      "blackcliffpole": "黑岩刺枪",
      "blackcliffslasher": "黑岩斩刀",
      "blackcliffwarbow": "黑岩战弓",
      "blacktassel": "黑缨枪",
      "bloodtaintedgreatsword": "沐浴龙血的剑",
      "calamityqueller": "息灾",
      "cinnabarspindle": "辰砂之纺锤",
      "compoundbow": "钢轮弓",
      "coolsteel": "冷刃",
      "crescentpike": "流月针",
      "darkironsword": "暗铁剑",
      "deathmatch": "决斗之枪",
      "debateclub": "以理服人",
      "dodocotales": "嘟嘟可故事集",
      "dragonsbane": "匣里灭辰",
      "dragonspinespear": "龙脊长枪",
      "dullblade": "无锋剑",
      "elegyfortheend": "终末嗟叹之诗",
      "emeraldorb": "翡玉法球",
      "engulfinglightning": "薙草之稻光",
      "everlastingmoonglow": "不灭月华",
      "eyeofperception": "昭心",
      "fadingtwilight": "落霞",
      "favoniuscodex": "西风秘典",
      "favoniusgreatsword": "西风大剑",
      "favoniuslance": "西风长枪",
      "favoniussword": "西风剑",
      "favoniuswarbow": "西风猎弓",
      "ferrousshadow": "铁影阔剑",
      "festeringdesire": "腐殖之剑",
      "filletblade": "吃虎鱼刀",
      "freedomsworn": "苍古自由之誓",
      "frostbearer": "忍冬之果",
      "hakushinring": "白辰之环",
      "halberd": "钺矛",
      "hamayumi": "破魔之弓",
      "harangeppakufutsu": "波乱月白经津",
      "harbingerofdawn": "黎明神剑",
      "huntersbow": "猎弓",
      "ironpoint": "铁尖枪",
      "ironsting": "铁蜂刺",
      "kagurasverity": "神乐之真意",
      "katsuragikirinagamasa": "桂木斩长正",
      "kitaincrossspear": "喜多院十文字",
      "lionsroar": "匣里龙吟",
      "lithicblade": "千岩古剑",
      "lithicspear": "千岩长枪",
      "lostprayertothesacredwinds": "四风原典",
      "luxurioussealord": "衔珠海皇",
      "magicguide": "魔导绪论",
      "mappamare": "万国诸海图谱",
      "memoryofdust": "尘世之锁",
      "messenger": "信使",
      "mistsplitterreforged": "雾切之回光",
      "mitternachtswaltz": "幽夜华尔兹",
      "mouunsmoon": "曚云之月",
      "oathsworneye": "证誓之明瞳",
      "oldmercspal": "佣兵重剑",
      "otherworldlystory": "异世界行记",
      "pocketgrimoire": "口袋魔导书",
      "polarstar": "冬极白星",
      "predator": "掠食者",
      "primordialjadecutter": "磐岩结绿",
      "primordialjadewingedspear": "和璞鸢",
      "prototypeamber": "试作金珀",
      "prototypearchaic": "试作古华",
      "prototypecrescent": "试作澹月",
      "prototyperancour": "试作斩岩",
      "prototypestarglitter": "试作星镰",
      "rainslasher": "雨裁",
      "ravenbow": "鸦羽弓",
      "recurvebow": "反曲弓",
      "redhornstonethresher": "赤角石溃杵",
      "royalbow": "宗室长弓",
      "royalgreatsword": "宗室大剑",
      "royalgrimoire": "宗室秘法录",
      "royallongsword": "宗室长剑",
      "royalspear": "宗室猎枪",
      "rust": "弓藏",
      "sacrificialbow": "祭礼弓",
      "sacrificialfragments": "祭礼残章",
      "sacrificialgreatsword": "祭礼大剑",
      "sacrificialsword": "祭礼剑",
      "seasonedhuntersbow": "历练的猎弓",
      "serpentspine": "螭骨剑",
      "sharpshootersoath": "神射手之誓",
      "silversword": "银剑",
      "skyridergreatsword": "飞天大御剑",
      "skyridersword": "飞天御剑",
      "skywardatlas": "天空之卷",
      "skywardblade": "天空之刃",
      "skywardharp": "天空之翼",
      "skywardpride": "天空之傲",
      "skywardspine": "天空之脊",
      "slingshot": "弹弓",
      "snowtombedstarsilver": "雪葬的星银",
      "solarpearl": "匣里日月",
      "songofbrokenpines": "松籁响起之时",
      "staffofhoma": "护摩之杖",
      "summitshaper": "斫峰之刃",
      "swordofdescension": "降临之剑",
      "thealleyflash": "暗巷闪光",
      "thebell": "钟剑",
      "theblacksword": "黑剑",
      "thecatch": "「渔获」",
      "theflute": "笛剑",
      "thestringless": "绝弦",
      "theunforged": "无工之剑",
      "theviridescenthunt": "苍翠猎弓",
      "thewidsith": "流浪乐章",
      "thrillingtalesofdragonslayers": "讨龙英杰谭",
      "thunderingpulse": "飞雷之弦振",
      "travelershandysword": "旅行剑",
      "twinnephrite": "甲级宝珏",
      "vortexvanquisher": "贯虹之槊",
      "wastergreatsword": "训练大剑",
      "wavebreakersfin": "断浪长鳍",
      "whiteblind": "白影剑",
      "whiteirongreatsword": "白铁大剑",
      "whitetassel": "白缨枪",
      "windblumeode": "风花之颂",
      "wineandsong": "暗巷的酒与诗",
      "wolfsgravestone": "狼的末路"
    }
  },
  "Japanese": {
    "artifact_names": {
      "archaicpetra": "悠久の磐岩",
      "berserker": "狂戦士",
      "blizzardstrayer": "氷風を彷徨う勇士",
      "bloodstainedchivalry": "血染めの騎士道",
      "braveheart": "勇士の心",
      "crimsonwitchofflames": "燃え盛る炎の魔女",
      "defenderswill": "守護の心",
      "echoesofanoffering": "来歆の余響",
      "emblemofseveredfate": "絶縁の旗印",
      "gambler": "博徒",
      "gladiatorsfinale": "剣闘士のフィナーレ",
      "heartofdepth": "沈淪の心",
      "huskofopulentdreams": "華館夢醒形骸記",
      "instructor": "教官",
      "lavawalker": "烈火を渡る賢者",
      "maidenbeloved": "愛される少女",
      "martialartist": "武人",
      "noblesseoblige": "旧貴族のしつけ",
      "oceanhuedclam": "海染硨磲",
      "paleflame": "蒼白の炎",
      "prayersfordestiny": "水祭りの人",
      "prayersforillumination": "火祭りの人",
      "prayersforwisdom": "雷祭りの人",
      "prayerstospringtime": "氷祭りの人",
      "resolutionofsojourner": "旅人の心",
      "retracingbolide": "逆飛びの流星",
      "scholar": "学者",
      "shimenawasreminiscence": "追憶のしめ縄",
      "tenacityofthemillelith": "千岩牢固",
      "theexile": "亡命者",
      "thunderingfury": "雷のような怒り",
      "thundersoother": "雷を鎮める尊者",
      "tinymiracle": "奇跡",
      "vermillionhereafter": "辰砂往生録",
      "viridescentvenerer": "翠緑の影",
      "wandererstroupe": "大地を流浪する楽団"
    },
    "character_names": {
      "aether": "空",
      "lumine": "蛍",
      "albedo": "アルベド",
      "aloy": "アーロイ",
      "amber": "アンバー",
      "barbara": "バーバラ",
      "beidou": "北斗",
      "bennett": "ベネット",
      "chongyun": "重雲",
      "diluc": "ディルック",
      "diona": "ディオナ",
      "eula": "エウルア",
      "fischl": "フィッシュル",
      "ganyu": "甘雨",
      "hutao": "胡桃",
      "jean": "ジン",
      "kazuha": "楓原万葉",
      "kaeya": "ガイア",
      "ayaka": "神里綾華",
      "keqing": "刻晴",
      "klee": "クレー",
      "sara": "九条裟羅",
      "lisa": "リサ",
      "mona": "モナ",
      "ningguang": "凝光",
      "noelle": "ノエル",
      "qiqi": "七七",
      "raiden": "雷電将軍",
      "razor": "レザー",
      "rosaria": "ロサリア",
      "kokomi": "珊瑚宮心海",
      "sayu": "早柚",
      "sucrose": "スクロース",
      "tartaglia": "タルタリヤ",
      "thoma": "トーマ",
      "venti": "ウェンティ",
      "xiangling": "香菱",
      "xiao": "魈",
      "xingqiu": "行秋",
      "xinyan": "辛炎",
      "yanfei": "煙緋",
      "yoimiya": "宵宮",
      "zhongli": "鍾離",
      "gorou": "ゴロー",
      "itto": "荒瀧一斗",
      "shenhe": "申鶴",
      "yunjin": "雲菫",
      "yaemiko": "八重神子",
      "ayato": "神里綾人",
      "yelan": "夜蘭",
      "travelerelectro": "旅人 (雷元素)",
      "traveleranemo": "旅人 (風元素)",
      "travelergeo": "旅人 (岩元素)"
    },
    "weapon_names": {
      "akuoumaru": "惡王丸",
      "alleyhunter": "ダークアレイの狩人",
      "amenomakageuchi": "天目影打",
      "amosbow": "アモスの弓",
      "apprenticesnotes": "生徒ノート",
      "aquasimulacra": "若水",
      "aquilafavonia": "風鷹剣",
      "beginnersprotector": "新米の長槍",
      "blackcliffagate": "黒岩の緋玉",
      "blackclifflongsword": "黒岩の長剣",
      "blackcliffpole": "黒岩の突槍",
      "blackcliffslasher": "黒岩の斬刀",
      "blackcliffwarbow": "黒岩の戦弓",
      "blacktassel": "黒纓槍",
      "bloodtaintedgreatsword": "龍血を浴びた剣",
      "calamityqueller": "息災",
      "cinnabarspindle": "シナバースピンドル",
      "compoundbow": "リングボウ",
      "coolsteel": "冷刃",
      "crescentpike": "流月の針",
      "darkironsword": "暗鉄剣",
      "deathmatch": "死闘の槍",
      "debateclub": "理屈責め",
      "dodocotales": "ドドコの物語",
      "dragonsbane": "匣中滅龍",
      "dragonspinespear": "ドラゴンスピア",
      "dullblade": "無鋒の剣",
      "elegyfortheend": "終焉を嘆く詩",
      "emeraldorb": "翡玉法珠",
      "engulfinglightning": "草薙の稲光",
      "everlastingmoonglow": "不滅の月華",
      "eyeofperception": "昭心",
      "fadingtwilight": "落霞",
      "favoniuscodex": "西風秘典",
      "favoniusgreatsword": "西風大剣",
      "favoniuslance": "西風長槍",
      "favoniussword": "西風剣",
      "favoniuswarbow": "西風猟弓",
      "ferrousshadow": "鉄影段平",
      "festeringdesire": "腐植の剣",
      "filletblade": "チ虎魚の刀",
      "freedomsworn": "蒼古なる自由への誓い",
      "frostbearer": "冬忍びの実",
      "hakushinring": "白辰の輪",
      "halberd": "鉾槍",
      "hamayumi": "破魔の弓",
      "harangeppakufutsu": "波乱月白経津",
      "harbingerofdawn": "黎明の神剣",
      "huntersbow": "狩猟弓",
      "ironpoint": "鉄尖槍",
      "ironsting": "鉄蜂の刺し",
      "kagurasverity": "神楽の真意",
      "katsuragikirinagamasa": "桂木斬長正",
      "kitaincrossspear": "喜多院十文字槍",
      "lionsroar": "匣中龍吟",
      "lithicblade": "千岩古剣",
      "lithicspear": "千岩長槍",
      "lostprayertothesacredwinds": "四風原典",
      "luxurioussealord": "銜玉の海皇",
      "magicguide": "魔導緒論",
      "mappamare": "万国諸海の図譜",
      "memoryofdust": "浮世の錠",
      "messenger": "文使い",
      "mistsplitterreforged": "霧切の廻光",
      "mitternachtswaltz": "幽夜のワルツ",
      "mouunsmoon": "曚雲の月",
      "oathsworneye": "誓いの明瞳",
      "oldmercspal": "傭兵の重剣",
      "otherworldlystory": "異世界旅行記",
      "pocketgrimoire": "ポケット魔導書",
      "polarstar": "冬極の白星",
      "predator": "プレデター",
      "primordialjadecutter": "磐岩結緑",
      "primordialjadewingedspear": "和璞鳶",
      "prototypeamber": "金珀·試作",
      "prototypearchaic": "古華·試作",
      "prototypecrescent": "澹月·試作",
      "prototyperancour": "斬岩·試作",
      "prototypestarglitter": "星鎌·試作",
      "rainslasher": "雨裁",
      "ravenbow": "鴉羽の弓",
      "recurvebow": "リカーブボウ",
      "redhornstonethresher": "赤角石塵滅砕",
      "royalbow": "旧貴族長弓",
      "royalgreatsword": "旧貴族大剣",
      "royalgrimoire": "旧貴族秘法録",
      "royallongsword": "旧貴族長剣",
      "royalspear": "旧貴族猟槍",
      "rust": "弓蔵",
      "sacrificialbow": "祭礼の弓",
      "sacrificialfragments": "祭礼の断片",
      "sacrificialgreatsword": "祭礼の大剣",
      "sacrificialsword": "祭礼の剣",
      "seasonedhuntersbow": "歴戦の狩猟弓",
      "serpentspine": "螭龍の剣",
      "sharpshootersoath": "シャープシューターの誓い",
      "silversword": "銀の剣",
      "skyridergreatsword": "飛天大御剣",
      "skyridersword": "飛天御剣",
      "skywardatlas": "天空の巻",
      "skywardblade": "天空の刃",
      "skywardharp": "天空の翼",
      "skywardpride": "天空の傲",
      "skywardspine": "天空の脊",
      "slingshot": "弾弓",
      "snowtombedstarsilver": "雪葬の星銀",
      "solarpearl": "匣中日月",
      "songofbrokenpines": "松韻の響く頃",
      "staffofhoma": "護摩の杖",
      "summitshaper": "斬山の刃",
      "swordofdescension": "降臨の剣",
      "thealleyflash": "ダークアレイの閃光",
      "thebell": "鐘の剣",
      "theblacksword": "黒剣",
      "thecatch": "「漁獲」",
      "theflute": "笛の剣",
      "thestringless": "絶弦",
      "theunforged": "無工の剣",
      "theviridescenthunt": "蒼翠の狩猟弓",
      "thewidsith": "流浪楽章",
      "thrillingtalesofdragonslayers": "龍殺しの英傑譚",
      "thunderingpulse": "飛雷の鳴弦",
      "travelershandysword": "旅道の剣",
      "twinnephrite": "特級の宝玉",
      "vortexvanquisher": "破天の槍",
      "wastergreatsword": "訓練用大剣",
      "wavebreakersfin": "斬波のひれ長",
      "whiteblind": "白影の剣",
      "whiteirongreatsword": "白鉄の大剣",
      "whitetassel": "白纓槍",
      "windblumeode": "風花の頌歌",
      "wineandsong": "ダークアレイの酒と詩",
      "wolfsgravestone": "狼の末路"
    }
  },
  "Spanish": {
    "artifact_names": {
      "archaicpetra": "Petra Arcaica",
      "berserker": "Berserker",
      "blizzardstrayer": "Nómada del Invierno",
      "bloodstainedchivalry": "Caballería Sanguinaria",
      "braveheart": "Corazón del Guerrero",
      "crimsonwitchofflames": "Bruja Carmesí en Llamas",
      "defenderswill": "Corazón del Guardián",
      "echoesofanoffering": "Eco del Sacrificio",
      "emblemofseveredfate": "Emblema del Destino",
      "gambler": "Jugadora",
      "gladiatorsfinale": "Final del Gladiador",
      "heartofdepth": "Corazón de las Profundidades",
      "huskofopulentdreams": "Cáscara de Sueños Opulentos",
      "instructor": "Instructor",
      "lavawalker": "Virtuoso Corredor de Lava",
      "maidenbeloved": "Doncella Amada",
      "martialartist": "Artista Marcial",
      "noblesseoblige": "Ritual Antiguo de la Nobleza",
      "oceanhuedclam": "Perla Oceánica",
      "paleflame": "Llamas Albinas",
      "prayersfordestiny": "Ritual del Destino",
      "prayersforillumination": "Ritual de la Iluminación",
      "prayersforwisdom": "Ritual de la Sabiduría",
      "prayerstospringtime": "Ritual de la Primavera",
      "resolutionofsojourner": "Corazón del Viajero",
      "retracingbolide": "Retroceso del Meteorito",
      "scholar": "Erudita",
      "shimenawasreminiscence": "Reminiscencia de la Purificación",
      "tenacityofthemillelith": "Tenacidad de la Geoarmada",
      "theexile": "Exiliado",
      "thunderingfury": "Furia del Trueno",
      "thundersoother": "Domador de Truenos",
      "tinymiracle": "Milagro",
      "vermillionhereafter": "Deceso del Cinabrio",
      "viridescentvenerer": "Sombra Verde Esmeralda",
      "wandererstroupe": "Orquesta del Errante"
    },
    "character_names": {
      "aether": "Éter",
      "lumine": "Lumina",
      "albedo": "Albedo",
      "aloy": "Aloy",
      "amber": "Amber",
      "barbara": "Bárbara",
      "beidou": "Beidou",
      "bennett": "Bennett",
      "chongyun": "Chongyun",
      "diluc": "Diluc",
      "diona": "Diona",
      "eula": "Eula",
      "fischl": "Fischl",
      "ganyu": "Ganyu",
      "hutao": "Hu Tao",
      "jean": "Jean",
      "kazuha": "Kaedehara Kazuha",
      "kaeya": "Kaeya",
      "ayaka": "Kamisato Ayaka",
      "keqing": "Keching",
      "klee": "Klee",
      "sara": "Kujou Sara",
      "lisa": "Lisa",
      "mona": "Mona",
      "ningguang": "Ninguang",
      "noelle": "Noelle",
      "qiqi": "Qiqi",
      "raiden": "Shogun Raiden",
      "razor": "Razor",
      "rosaria": "Rosaria",
      "kokomi": "Sangonomiya Kokomi",
      "sayu": "Sayu",
      "sucrose": "Sacarosa",
      "tartaglia": "Tartaglia",
      "thoma": "Thoma",
      "venti": "Venti",
      "xiangling": "Xiangling",
      "xiao": "Xiao",
      "xingqiu": "Xingchiu",
      "xinyan": "Xinyan",
      "yanfei": "Yanfei",
      "yoimiya": "Yoimiya",
      "zhongli": "Zhongli",
      "gorou": "Gorou",
      "itto": "Arataki Itto",
      "shenhe": "Shenhe",
      "yunjin": "Yun Jin",
      "yaemiko": "Yae Miko",
      "ayato": "Kamisato Ayato",
      "yelan": "Yelan",
      "travelerelectro": "Viajera (Electro)",
      "traveleranemo": "Viajera (Anemo)",
      "travelergeo": "Viajera (Geo)"
    },
    "weapon_names": {
      "akuoumaru": "Rey del Mal",
      "alleyhunter": "Cazador del Callejón",
      "amenomakageuchi": "Espada Amenoma Gemela",
      "amosbow": "Arco de Amos",
      "apprenticesnotes": "Apuntes del Aprendiz",
      "aquasimulacra": "Aqua Simulacra",
      "aquilafavonia": "Aquila Favonia",
      "beginnersprotector": "Lanza del Principiante",
      "blackcliffagate": "Ágata del Peñasco Oscuro",
      "blackclifflongsword": "Espada Larga del Peñasco Oscuro",
      "blackcliffpole": "Lanza del Peñasco Oscuro",
      "blackcliffslasher": "Gran Hoja del Peñasco Oscuro",
      "blackcliffwarbow": "Arco del Peñasco Oscuro",
      "blacktassel": "Borla Negra",
      "bloodtaintedgreatsword": "Gran Espada Sangrienta",
      "calamityqueller": "Pacificadora del Desastre",
      "cinnabarspindle": "Huso de Cinabrio",
      "compoundbow": "Arco Compuesto",
      "coolsteel": "Hoja Fría",
      "crescentpike": "Pica Luna Creciente",
      "darkironsword": "Espada de Hierro Oscuro",
      "deathmatch": "Lanza del Duelo",
      "debateclub": "Garrote del Debate",
      "dodocotales": "Cuentos de Dodoco",
      "dragonsbane": "Perdición del Dragón",
      "dragonspinespear": "Lanza de Espinadragón",
      "dullblade": "Hoja Desafilada",
      "elegyfortheend": "Elegía del Fin",
      "emeraldorb": "Orbe Esmeralda",
      "engulfinglightning": "Luz del Segador",
      "everlastingmoonglow": "Luna Inalterable",
      "eyeofperception": "Ojo de la Perspicacia",
      "fadingtwilight": "Desvanecimiento del Crepúsculo",
      "favoniuscodex": "Códice de Favonius",
      "favoniusgreatsword": "Gran Espada de Favonius",
      "favoniuslance": "Lanza de Favonius",
      "favoniussword": "Espada de Favonius",
      "favoniuswarbow": "Arco de Favonius",
      "ferrousshadow": "Sombra Férrea",
      "festeringdesire": "Deseo Ponzoñoso",
      "filletblade": "Hoja de Filetear",
      "freedomsworn": "Juramento por la Libertad",
      "frostbearer": "Frío Eterno",
      "hakushinring": "Anillo de Hakushin",
      "halberd": "Alabarda",
      "hamayumi": "Masacrademonios",
      "harangeppakufutsu": "Luna Ondulante de Futsu",
      "harbingerofdawn": "Espada del Alba",
      "huntersbow": "Arco del Cazador",
      "ironpoint": "Punta de Hierro",
      "ironsting": "Espina de Hierro",
      "kagurasverity": "Axioma de la Kagura",
      "katsuragikirinagamasa": "Asesinato de Katsuragi",
      "kitaincrossspear": "Cruz de Kitain",
      "lionsroar": "Rugido del León",
      "lithicblade": "Espada Lítica",
      "lithicspear": "Lanza Lítica",
      "lostprayertothesacredwinds": "Oración Perdida a los Vientos Sagrados",
      "luxurioussealord": "Rey de los Mares",
      "magicguide": "Guía Mágica",
      "mappamare": "Carta Náutica",
      "memoryofdust": "Candado Terrenal",
      "messenger": "Mensajero",
      "mistsplitterreforged": "Reflejo de las Tinieblas",
      "mitternachtswaltz": "Vals Nocturno",
      "mouunsmoon": "Luna de Mouun",
      "oathsworneye": "Ojo del Juramento",
      "oldmercspal": "Espada del Mercenario",
      "otherworldlystory": "Historias de Otros Mundos",
      "pocketgrimoire": "Grimorio de Bolsillo",
      "polarstar": "Estrella Invernal",
      "predator": "Depredador",
      "primordialjadecutter": "Cortador de Jade Primordial",
      "primordialjadewingedspear": "Halcón de Jade",
      "prototypeamber": "Prototipo Ámbar",
      "prototypearchaic": "Prototipo Arcaico",
      "prototypecrescent": "Prototipo Luz de Luna",
      "prototyperancour": "Prototipo Rencor",
      "prototypestarglitter": "Prototipo Estelar",
      "rainslasher": "Segadora de la Lluvia",
      "ravenbow": "Arco de Cuervo",
      "recurvebow": "Arco Recurvo",
      "redhornstonethresher": "Espadón Cornirrojo",
      "royalbow": "Arco Real",
      "royalgreatsword": "Gran Espada Real",
      "royalgrimoire": "Grimorio Real",
      "royallongsword": "Espada Real Larga",
      "royalspear": "Lanza de Caza Real",
      "rust": "Herrumbre",
      "sacrificialbow": "Arco del Sacrificio",
      "sacrificialfragments": "Memorias de Sacrificios",
      "sacrificialgreatsword": "Gran Espada de Sacrificio",
      "sacrificialsword": "Espada de Sacrificio",
      "seasonedhuntersbow": "Arco del Cazador Estacional",
      "serpentspine": "Médula de la Serpiente Marina",
      "sharpshootersoath": "Juramento del Arquero",
      "silversword": "Espada Plateada",
      "skyridergreatsword": "Gran Espada Surcacielos",
      "skyridersword": "Espada Surcacielos",
      "skywardatlas": "Pergamino Celestial",
      "skywardblade": "Hoja Afilada Celestial",
      "skywardharp": "Alas Celestiales",
      "skywardpride": "Orgullo Celestial",
      "skywardspine": "Púa Celestial",
      "slingshot": "Tirachinas",
      "snowtombedstarsilver": "Argento Estelar de las Nieves",
      "solarpearl": "Perla Solar",
      "songofbrokenpines": "Oda de los Pinos",
      "staffofhoma": "Báculo de Homa",
      "summitshaper": "Rompemontañas",
      "swordofdescension": "Espada del Descenso",
      "thealleyflash": "Destello en la Oscuridad",
      "thebell": "Espada del Tiempo",
      "theblacksword": "Espada Negra",
      "thecatch": "La Captura",
      "theflute": "Flauta",
      "thestringless": "Último Acorde",
      "theunforged": "Espada de la Desidia",
      "theviridescenthunt": "Arco de la Cazadora Esmeralda",
      "thewidsith": "Sinfonía de los Merodeadores",
      "thrillingtalesofdragonslayers": "Cuentos de Cazadores de Dragones",
      "thunderingpulse": "Agitador del Relámpago",
      "travelershandysword": "Espada del Viajero",
      "twinnephrite": "Nefrita Gemela",
      "vortexvanquisher": "Lanza Perforanubes",
      "wastergreatsword": "Gran Espada del Guerrero",
      "wavebreakersfin": "Aleta Cortaolas",
      "whiteblind": "Sombra Blanca",
      "whiteirongreatsword": "Espada de Hierro Blanco",
      "whitetassel": "Borla Blanca",
      "windblumeode": "Oda a las Flores de Viento",
      "wineandsong": "Vino y Poesía",
      "wolfsgravestone": "Lápida del Lobo"
    }
  },
  "Russian": {
    "artifact_names": {
      "archaicpetra": "Архаичный камень",
      "berserker": "Берсерк",
      "blizzardstrayer": "Заблудший в метели",
      "bloodstainedchivalry": "Рыцарь крови",
      "braveheart": "Душа храбреца",
      "crimsonwitchofflames": "Горящая алая ведьма",
      "defenderswill": "Воля защитника",
      "echoesofanoffering": "Отголоски подношения",
      "emblemofseveredfate": "Эмблема рассечённой судьбы",
      "gambler": "Азартный игрок",
      "gladiatorsfinale": "Конец гладиатора",
      "heartofdepth": "Сердце глубин",
      "huskofopulentdreams": "Кокон сладких грёз",
      "instructor": "Инструктор",
      "lavawalker": "Ступающий по лаве",
      "maidenbeloved": "Возлюбленная юная дева",
      "martialartist": "Воин",
      "noblesseoblige": "Церемония древней знати",
      "oceanhuedclam": "Моллюск морских красок",
      "paleflame": "Бледный огонь",
      "prayersfordestiny": "Шаман воды",
      "prayersforillumination": "Шаман огня",
      "prayersforwisdom": "Шаман молнии",
      "prayerstospringtime": "Шаман льда",
      "resolutionofsojourner": "Решимость временщика",
      "retracingbolide": "Встречная комета",
      "scholar": "Учёный",
      "shimenawasreminiscence": "Воспоминания Симэнавы",
      "tenacityofthemillelith": "Стойкость Миллелита",
      "theexile": "Изгнанник",
      "thunderingfury": "Громогласный рёв ярости",
      "thundersoother": "Усмиряющий гром",
      "tinymiracle": "Маленькое чудо",
      "vermillionhereafter": "Киноварное загробье",
      "viridescentvenerer": "Изумрудная тень",
      "wandererstroupe": "Странствующий ансамбль"
    },
    "character_names": {
      "aether": "Итэр",
      "lumine": "Люмин",
      "albedo": "Альбедо",
      "aloy": "Элой",
      "amber": "Эмбер",
      "barbara": "Барбара",
      "beidou": "Бэй Доу",
      "bennett": "Беннет",
      "chongyun": "Чун Юнь",
      "diluc": "Дилюк",
      "diona": "Диона",
      "eula": "Эола",
      "fischl": "Фишль",
      "ganyu": "Гань Юй",
      "hutao": "Ху Тао",
      "jean": "Джинн",
      "kazuha": "Кадзуха",
      "kaeya": "Кэйа",
      "ayaka": "Аяка",
      "keqing": "Кэ Цин",
      "klee": "Кли",
      "sara": "Сара",
      "lisa": "Лиза",
      "mona": "Мона",
      "ningguang": "Нин Гуан",
      "noelle": "Ноэлль",
      "qiqi": "Ци Ци",
      "raiden": "Райдэн",
      "razor": "Рэйзор",
      "rosaria": "Розария",
      "kokomi": "Кокоми",
      "sayu": "Саю",
      "sucrose": "Сахароза",
      "tartaglia": "Тарталья",
      "thoma": "Тома",
      "venti": "Венти",
      "xiangling": "Сян Лин",
      "xiao": "Сяо",
      "xingqiu": "Син Цю",
      "xinyan": "Синь Янь",
      "yanfei": "Янь Фэй",
      "yoimiya": "Ёимия",
      "zhongli": "Чжун Ли",
      "gorou": "Горо",
      "itto": "Итто",
      "shenhe": "Шэнь Хэ",
      "yunjin": "Юнь Цзинь",
      "yaemiko": "Яэ Мико",
      "ayato": "Аято",
      "yelan": "Е Лань",
      "travelerelectro": "Путешественница (Электро)",
      "traveleranemo": "Путешественница (Анемо)",
      "travelergeo": "Путешественница (Гео)"
    },
    "weapon_names": {
      "akuoumaru": "Акуомару",
      "alleyhunter": "Охотник во тьме",
      "amenomakageuchi": "Амэнома Кагэути",
      "amosbow": "Лук Амоса",
      "apprenticesnotes": "Записи ученика",
      "aquasimulacra": "Аква симулякрум",
      "aquilafavonia": "Меч Сокола",
      "beginnersprotector": "Копьё новичка",
      "blackcliffagate": "Черногорский агат",
      "blackclifflongsword": "Черногорский длинный меч",
      "blackcliffpole": "Черногорская пика",
      "blackcliffslasher": "Черногорская бритва",
      "blackcliffwarbow": "Черногорский боевой лук",
      "blacktassel": "Чёрная кисть",
      "bloodtaintedgreatsword": "Меч драконьей крови",
      "calamityqueller": "Усмиритель бед",
      "cinnabarspindle": "Киноварное веретено",
      "compoundbow": "Составной лук",
      "coolsteel": "Холодное лезвие",
      "crescentpike": "Пика полумесяца",
      "darkironsword": "Тёмный железный меч",
      "deathmatch": "Смертельный бой",
      "debateclub": "Дубина переговоров",
      "dodocotales": "Истории Додоко",
      "dragonsbane": "Гроза драконов",
      "dragonspinespear": "Копьё Драконьего хребта",
      "dullblade": "Тупой меч",
      "elegyfortheend": "Элегия погибели",
      "emeraldorb": "Изумрудный шар",
      "engulfinglightning": "Сияющая жатва",
      "everlastingmoonglow": "Вечное лунное сияние",
      "eyeofperception": "Око сознания",
      "fadingtwilight": "Гаснущие сумерки",
      "favoniuscodex": "Кодекс Фавония",
      "favoniusgreatsword": "Двуручный меч Фавония",
      "favoniuslance": "Копьё Фавония",
      "favoniussword": "Меч Фавония",
      "favoniuswarbow": "Боевой лук Фавония",
      "ferrousshadow": "Металлическая тень",
      "festeringdesire": "Осквернённое желание",
      "filletblade": "Филейный нож",
      "freedomsworn": "Клятва свободы",
      "frostbearer": "Плод вечной мерзлоты",
      "hakushinring": "Кольцо Хакусин",
      "halberd": "Алебарда Миллелита",
      "hamayumi": "Хамаюми",
      "harangeppakufutsu": "Харан гэппаку фуцу",
      "harbingerofdawn": "Предвестник зари",
      "huntersbow": "Лук охотника",
      "ironpoint": "Железный наконечник",
      "ironsting": "Стальное жало",
      "kagurasverity": "Истина кагура",
      "katsuragikirinagamasa": "Кацурагикири Нагамаса",
      "kitaincrossspear": "Крест-копьё Китаин",
      "lionsroar": "Драконий рык",
      "lithicblade": "Каменный меч",
      "lithicspear": "Каменное копьё",
      "lostprayertothesacredwinds": "Молитва святым ветрам",
      "luxurioussealord": "Благодатный владыка вод",
      "magicguide": "Руководство по магии",
      "mappamare": "Морской атлас",
      "memoryofdust": "Память о пыли",
      "messenger": "Посыльный",
      "mistsplitterreforged": "Рассекающий туман",
      "mitternachtswaltz": "Вальс Нирваны Ночи",
      "mouunsmoon": "Луна Моун",
      "oathsworneye": "Око клятвы",
      "oldmercspal": "Лучший друг наёмника",
      "otherworldlystory": "Потусторонняя история",
      "pocketgrimoire": "Карманный гримуар",
      "polarstar": "Полярная звезда",
      "predator": "Хищник",
      "primordialjadecutter": "Драгоценный омут",
      "primordialjadewingedspear": "Нефритовый коршун",
      "prototypeamber": "Прототип: Янтарь",
      "prototypearchaic": "Прототип: Архаичный",
      "prototypecrescent": "Прототип: Полумесяц",
      "prototyperancour": "Прототип: Злоба",
      "prototypestarglitter": "Прототип: Звёздный блеск",
      "rainslasher": "Дождерез",
      "ravenbow": "Лук ворона",
      "recurvebow": "Изогнутый лук",
      "redhornstonethresher": "Краснорогий камнеруб",
      "royalbow": "Королевский лук",
      "royalgreatsword": "Королевский двуручный меч",
      "royalgrimoire": "Королевский гримуар",
      "royallongsword": "Меч аристократов",
      "royalspear": "Королевское копьё",
      "rust": "Ржавый лук",
      "sacrificialbow": "Церемониальный лук",
      "sacrificialfragments": "Церемониальные мемуары",
      "sacrificialgreatsword": "Церемониальный двуручный меч",
      "sacrificialsword": "Церемониальный меч",
      "seasonedhuntersbow": "Лук опытного охотника",
      "serpentspine": "Меч драконьей кости",
      "sharpshootersoath": "Клятва стрелка",
      "silversword": "Серебряный меч",
      "skyridergreatsword": "Большой меч небесного всадника",
      "skyridersword": "Меч небесного всадника",
      "skywardatlas": "Небесный атлас",
      "skywardblade": "Небесный меч",
      "skywardharp": "Небесное крыло",
      "skywardpride": "Небесное величие",
      "skywardspine": "Небесная ось",
      "slingshot": "Рогатка",
      "snowtombedstarsilver": "Заснеженное звёздное серебро",
      "solarpearl": "Солнечная жемчужина",
      "songofbrokenpines": "Песнь разбитых сосен",
      "staffofhoma": "Посох Хомы",
      "summitshaper": "Кромсатель пиков",
      "swordofdescension": "Меч нисхождения",
      "thealleyflash": "Вспышка во тьме",
      "thebell": "Меч-колокол",
      "theblacksword": "Чёрный меч",
      "thecatch": "«Улов»",
      "theflute": "Меч-флейта",
      "thestringless": "Бесструнный",
      "theunforged": "Некованый",
      "theviridescenthunt": "Зелёный лук",
      "thewidsith": "Песнь странника",
      "thrillingtalesofdragonslayers": "Эпос о драконоборцах",
      "thunderingpulse": "Громовой пульс",
      "travelershandysword": "Меч путешественника",
      "twinnephrite": "Парный нефрит",
      "vortexvanquisher": "Покоритель вихря",
      "wastergreatsword": "Двуручный меч богатыря",
      "wavebreakersfin": "Режущий волны плавник",
      "whiteblind": "Белая тень",
      "whiteirongreatsword": "Меч из белого железа",
      "whitetassel": "Белая кисть",
      "windblumeode": "Ода анемонии",
      "wineandsong": "Вино и песни",
      "wolfsgravestone": "Волчья погибель"
    }
  },
  "German": {
    "artifact_names": {
      "archaicpetra": "Archaischer Fels",
      "berserker": "Berserker",
      "blizzardstrayer": "Im Schnee irrender Recke",
      "bloodstainedchivalry": "Blutiger Weg eines Ritters",
      "braveheart": "Wagemut",
      "crimsonwitchofflames": "Brennende Pyro-Hexe",
      "defenderswill": "Beschützerinstinkt",
      "echoesofanoffering": "Echo des Opferfests",
      "emblemofseveredfate": "Wappen des getrennten Schicksals",
      "gambler": "Glücksspieler",
      "gladiatorsfinale": "Verbeugung des Gladiators",
      "heartofdepth": "Tief im Herzen",
      "huskofopulentdreams": "Schale der üppigen Träume",
      "instructor": "Ausbilder",
      "lavawalker": "Feuer durchwandernder Heiliger",
      "maidenbeloved": "Ins Herz geschlossenes Mädchen",
      "martialartist": "Kampfkünstler",
      "noblesseoblige": "Altes Hofritual",
      "oceanhuedclam": "Meeresmuschel",
      "paleflame": "Fahle Flammen",
      "prayersfordestiny": "Wasseropfer",
      "prayersforillumination": "Feueropfer",
      "prayersforwisdom": "Blitzopfer",
      "prayerstospringtime": "Eisopfer",
      "resolutionofsojourner": "Fernweh",
      "retracingbolide": "Umgekehrter Meteor",
      "scholar": "Gelehrter",
      "shimenawasreminiscence": "Gedenken an Shimenawa",
      "tenacityofthemillelith": "Zähigkeit der Millelithen",
      "theexile": "Verbannter",
      "thunderingfury": "Donnernder Zorn",
      "thundersoother": "Donner beruhigender Weiser",
      "tinymiracle": "Wunder",
      "vermillionhereafter": "Vergangenheit des Zinnobers",
      "viridescentvenerer": "Grünlicher Schatten",
      "wandererstroupe": "Wanderorchester"
    },
    "character_names": {
      "aether": "Leer",
      "lumine": "Lumine",
      "albedo": "Albedo",
      "aloy": "Aloy",
      "amber": "Amber",
      "barbara": "Barbara",
      "beidou": "Beidou",
      "bennett": "Bennett",
      "chongyun": "Chongyun",
      "diluc": "Diluc",
      "diona": "Diona",
      "eula": "Eula",
      "fischl": "Fischl",
      "ganyu": "Ganyu",
      "hutao": "Hu Tao",
      "jean": "Jean",
      "kazuha": "Kaedehara Kazuha",
      "kaeya": "Kaeya",
      "ayaka": "Kamisato Ayaka",
      "keqing": "Keqing",
      "klee": "Klee",
      "sara": "Kujou Sara",
      "lisa": "Lisa",
      "mona": "Mona",
      "ningguang": "Ningguang",
      "noelle": "Noelle",
      "qiqi": "Qiqi",
      "raiden": "Shougun Raiden",
      "razor": "Razor",
      "rosaria": "Rosaria",
      "kokomi": "Sangonomiya Kokomi",
      "sayu": "Sayu",
      "sucrose": "Saccharose",
      "tartaglia": "Tartaglia",
      "thoma": "Thoma",
      "venti": "Venti",
      "xiangling": "Xiangling",
      "xiao": "Xiao",
      "xingqiu": "Xingqiu",
      "xinyan": "Xinyan",
      "yanfei": "Yanfei",
      "yoimiya": "Yoimiya",
      "zhongli": "Zhongli",
      "gorou": "Gorou",
      "itto": "Arataki Itto",
      "shenhe": "Shenhe",
      "yunjin": "Yun Jin",
      "yaemiko": "Yae Miko",
      "ayato": "Kamisato Ayato",
      "yelan": "Yelan",
      "travelerelectro": "Reisende (Elektro)",
      "traveleranemo": "Reisende (Anemo)",
      "travelergeo": "Reisende (Geo)"
    },
    "weapon_names": {
      "akuoumaru": "Akuoumaru",
      "alleyhunter": "Gassenjäger",
      "amenomakageuchi": "Amenoma Kageuchi",
      "amosbow": "Amos’ Bogen",
      "apprenticesnotes": "Aufzeichnungen eines Lehrlings",
      "aquasimulacra": "Aqua Simulacra",
      "aquilafavonia": "Windfalke",
      "beginnersprotector": "Anfängerlanze",
      "blackcliffagate": "Schwarzstein-Achat",
      "blackclifflongsword": "Schwarzstein-Langschwert",
      "blackcliffpole": "Schwarzsteinlanze",
      "blackcliffslasher": "Schwarzstein-Schneide",
      "blackcliffwarbow": "Schwarzstein-Kriegsbogen",
      "blacktassel": "Schwarze Quaste",
      "bloodtaintedgreatsword": "Drachenblutschwert",
      "calamityqueller": "Ende des Unheils",
      "cinnabarspindle": "Zinnoberspindel",
      "compoundbow": "Compoundbogen",
      "coolsteel": "Kühle Klinge",
      "crescentpike": "Mondpike",
      "darkironsword": "Dunkles Eisenschwert",
      "deathmatch": "Duelllanze",
      "debateclub": "Schlagfestes Argument",
      "dodocotales": "Dodoco-Geschichten",
      "dragonsbane": "Drachenschreck",
      "dragonspinespear": "Drachengratspeer",
      "dullblade": "Stumpfes Schwert",
      "elegyfortheend": "Letzter Seufzer",
      "emeraldorb": "Jadekugel",
      "engulfinglightning": "Grasschnittstrahl",
      "everlastingmoonglow": "Ewiger Mondschein",
      "eyeofperception": "Herzensblick",
      "fadingtwilight": "Ausklingende Dämmerung",
      "favoniuscodex": "Favonius-Kodex",
      "favoniusgreatsword": "Favonius-Großschwert",
      "favoniuslance": "Favonius-Lanze",
      "favoniussword": "Favonius-Schwert",
      "favoniuswarbow": "Favonius-Jagdbogen",
      "ferrousshadow": "Eiserner Schatten",
      "festeringdesire": "Schwert der Verderbnis",
      "filletblade": "Filetiermesser",
      "freedomsworn": "Blasser Schwur der Freiheit",
      "frostbearer": "Permafrostfrucht",
      "hakushinring": "Hakushin-Ring",
      "halberd": "Hellebarde",
      "hamayumi": "Hamayumi",
      "harangeppakufutsu": "Haran Geppaku Futsu",
      "harbingerofdawn": "Schwert der Dämmerung",
      "huntersbow": "Jagdbogen",
      "ironpoint": "Eisenlanze",
      "ironsting": "Eisenstich",
      "kagurasverity": "Kaguras Wahrheit",
      "katsuragikirinagamasa": "Katsuragikiri Nagamasa",
      "kitaincrossspear": "Kitain-Kreuzlanze",
      "lionsroar": "Drachenschrei",
      "lithicblade": "Antikes Millelithenschwert",
      "lithicspear": "Millelithenlanze",
      "lostprayertothesacredwinds": "Gebete der Vier Winde",
      "luxurioussealord": "Luxuriöser Seekönig",
      "magicguide": "Magieführer",
      "mappamare": "Illustrationen von Landen und Wassern",
      "memoryofdust": "Ketten des Diesseits",
      "messenger": "Überbringer",
      "mistsplitterreforged": "Widerschein des Nebelsplitters",
      "mitternachtswaltz": "Mitternachtswalzer",
      "mouunsmoon": "Mouun-Mond",
      "oathsworneye": "Auge des Gelöbnisses",
      "oldmercspal": "Söldnerzweihänder",
      "otherworldlystory": "Geschichten einer anderen Welt",
      "pocketgrimoire": "Taschenzauberbuch",
      "polarstar": "Polarstern",
      "predator": "Raubtier",
      "primordialjadecutter": "Moosgrüner Fels",
      "primordialjadewingedspear": "Urzeitlicher Jadespeer",
      "prototypeamber": "Bernstein-Prototyp",
      "prototypearchaic": "Guhua-Prototyp",
      "prototypecrescent": "Mondschein-Prototyp",
      "prototyperancour": "Steinschneider-Prototyp",
      "prototypestarglitter": "Sternsichel-Prototyp",
      "rainslasher": "Regenschnitter",
      "ravenbow": "Rabenbogen",
      "recurvebow": "Reflexbogen",
      "redhornstonethresher": "Rothorn-Steinbrecher",
      "royalbow": "Königlicher Langbogen",
      "royalgreatsword": "Königliches Großschwert",
      "royalgrimoire": "Königliches Zauberbuch",
      "royallongsword": "Königliches Langschwert",
      "royalspear": "Königlicher Jagdspeer",
      "rust": "Rostiger Bogen",
      "sacrificialbow": "Opferbogen",
      "sacrificialfragments": "Opferrituale",
      "sacrificialgreatsword": "Opfergroßschwert",
      "sacrificialsword": "Opferschwert",
      "seasonedhuntersbow": "Alter Jagdbogen",
      "serpentspine": "Knochenschwert",
      "sharpshootersoath": "Eid des Scharfschützen",
      "silversword": "Silberschwert",
      "skyridergreatsword": "Himmelsflug-Großschwert",
      "skyridersword": "Himmelsflug-Schwert",
      "skywardatlas": "Himmelsatlas",
      "skywardblade": "Himmelsklinge",
      "skywardharp": "Himmelsflügel",
      "skywardpride": "Stolz des Himmels",
      "skywardspine": "Himmelsgrat",
      "slingshot": "Steinschleuder",
      "snowtombedstarsilver": "Unter Schnee begrabenes Sternsilber",
      "solarpearl": "Sonne und Mond",
      "songofbrokenpines": "Kiefernklang",
      "staffofhoma": "Homa-Stab",
      "summitshaper": "Gipfelbrecher",
      "swordofdescension": "Schwert der Niederkunft",
      "thealleyflash": "Gassenleuchte",
      "thebell": "Glocke",
      "theblacksword": "Schwarzes Schwert",
      "thecatch": "„Der Fang“",
      "theflute": "Flöte",
      "thestringless": "Der Sehnenlose",
      "theunforged": "Grobes Schwert",
      "theviridescenthunt": "Grasgrüner Jagdbogen",
      "thewidsith": "Landstreichernoten",
      "thrillingtalesofdragonslayers": "Von den Heldentaten der Drachenbezwinger",
      "thunderingpulse": "Donnerpuls",
      "travelershandysword": "Reiseschwert",
      "twinnephrite": "Zwillingsnephrit",
      "vortexvanquisher": "Regenbogenstecher",
      "wastergreatsword": "Übungsgroßschwert",
      "wavebreakersfin": "Wellenbrecherflosse",
      "whiteblind": "Weißer Schatten",
      "whiteirongreatsword": "Weißes Eisengroßschwert",
      "whitetassel": "Weiße Quaste",
      "windblumeode": "Windblumenode",
      "wineandsong": "Wein und Gesang in den Gassen",
      "wolfsgravestone": "Wolfsgrab"
    }
  }
}
