{
    "advanced": {
        "action_list": "动作列表",
        "helpers": "辅助器",
        "hide": "隐藏",
        "loading_workers": "加载工作器中",
        "run": "运行",
        "show": "显示",
        "sim_options": "模拟选项",
        "substat_helper": "副属性辅助器",
        "workers_available": "可用工作器："
    },
    "character": {
        "artifact_stats": "圣遗物属性",
        "c_post": "命",
        "c_pre": "",
        "lvl": "等级",
        "talents": "天赋"
    },
    "characteredit": {
        "add_set_bonus": "添加套装效果",
        "anemo": "风元素伤害加成",
        "artifact_stats_main": "圣遗物属性（主词条+副词条）",
        "ascension": "突破",
        "atk_atk": "攻击力",
        "attack": "普通攻击",
        "burst": "元素爆发",
        "cd": "暴击伤害",
        "change": "更换",
        "character": "角色",
        "cons": "命之座",
        "cr": "暴击率",
        "cryo": "冰元素伤害加成",
        "def_def": "防御力",
        "electro": "雷元素伤害加成",
        "em": "元素精通",
        "er": "元素充能效率",
        "geo": "岩元素伤害加成",
        "heal": "治疗加成",
        "hp_hp": "生命值",
        "hydro": "水元素伤害加成",
        "level": "等级",
        "phy": "物理伤害加成",
        "pyro": "火元素伤害加成",
        "refine": "精炼",
        "set_bonus": "套装效果：",
        "skill": "元素战技",
        "weapons_and_artifacts": "武器和圣遗物"
    },
    "components": {
        "close": "关闭",
        "currently_loaded_workers": "当前加载的工作器：",
        "no_characters": "没有角色",
        "result_post": "毫秒，平均DPS：",
        "result_pre": "模拟完成，用时",
        "running_simulation": "正在运行模拟：",
        "see_results_in": "在视图中查看结果",
        "set": "设置",
        "simulation_exited_with": "模拟中发生了错误：",
        "workers_post": "个工作器运行模拟",
        "workers_pre": "正在使用"
    },
    "dash": {
        "about": "关于",
        "advanced_mode": "进阶模式",
        "contribute": "合作开发",
        "desktop_tool": "桌面版工具",
        "documentation": "文档",
        "is_a_team": "是一个原神配队DPS模拟器。快点击下面的一个选项开始吧！",
        "simulator": "模拟器",
        "teams_db": "配队数据库",
        "viewer": "视图"
    },
    "db": {
        "author": "作者：",
        "average_dps_per": "平均DPS：",
        "c_post": "命",
        "c_pre": "",
        "cancel": "取消",
        "character": "角色",
        "continue": "继续",
        "description": "描述：",
        "details": "详情",
        "er": "元素充能效率：",
        "error_loading_database": "加载数据库失败。找不到数据",
        "filters": "过滤器：",
        "hash": "哈希：",
        "load_in_simulator": "加载到模拟器",
        "loading": "加载中……",
        "number_of_targets": "目标数：",
        "r": " 精",
        "show_in_viewer": "在视图中展示",
        "total_dps": "总DPS：",
        "type_to_search": "搜索…",
        "weapon": "武器"
    },
    "footer": {
        "gcsim_is_not": "gcsim不是官方工具，原神的游戏内容和素材的商标和版权归米哈游所有。本网站的国际化汉化支持来自：古月浪子。"
    },
    "elements": {
        "none": "无属性",
        "anemo": "风属性",
        "geo": "岩属性",
        "pyro": "火属性",
        "cryo": "冰属性",
        "hydro": "水属性",
        "electro": "雷属性",
        "dendro": "草属性"
    },
    "links": {
        "error_loading_database": "加载数据库失败。找不到数据"
    },
    "importer": {
        "import_success": "GOOD信息库已成功上载"
    },
    "nav": {
        "about": "关于",
        "advanced": "进阶",
        "chinese": "中文",
        "english": "English",
        "japanese": "日本語",
        "spanish": "Español",
        "russian": "Русский",
        "german": "Deutsch",
        "language": "语言：",
        "simulator": "模拟器",
        "teams_db": "配队数据库",
        "viewer": "视图"
    },
    "sim": {
        "loading_simulator_please": "加载模拟器中。请稍候"
    },
    "simple": {
        "action_list": "动作列表",
        "add_character": "添加角色",
        "discord_post": "上有大量用户提交的动作列表可供参考。",
        "discord_pre": "在下面的文本框中输入你希望使用的动作列表。我们在",
        "documentation": "文档",
        "documentation_post": "，其中包含有关如何编写顺序列表（以前称为计算模式）的简单教程。",
        "documentation_pre": "或者，查看我们的",
        "done": "完成",
        "exit_edit": "退出编辑",
        "give_me_back": "将我的小提示还回来！",
        "hide": "隐藏",
        "hide_all_tips": "隐藏所有小提示",
        "loading_workers": "加载工作器中",
        "run": "运行",
        "show": "显示",
        "sim_options": "模拟选项",
        "start_by_adding": "快开始添加队伍成员吧",
        "team": "配队",
        "video": "视频",
        "video_post": "来了解更多。",
        "video_pre": "开始添加角色到你的队伍中吧。查看这个",
        "workers_available": "可用工作器："
    },
    "src": {
        "this_page_is": "此页面正在施工中。敬请期待！"
    },
    "viewer": {
        "ability_usage_count": "天赋使用次数（平均）",
        "api_key_supporters": "API密钥（仅限支持者）",
        "attack": "攻击力",
        "average_character_field": "角色平均站场时间",
        "average_dps_by": "角色平均DPS",
        "avg_dps": "平均DPS",
        "built_on": "，时间：",
        "cancel": "取消",
        "cd": "暴击伤害",
        "clear": "清空",
        "close": "关闭",
        "combined": "合计",
        "config": "配置",
        "continue": "继续",
        "copied_to_clipboard": "复制到剪切板",
        "copy": "复制",
        "copy_link": "复制链接",
        "cr": "暴击率",
        "create_a_shareable": "创建分享链接",
        "damage_dealt_over": "伤害随时间的分布",
        "debug": "调试",
        "debug_settings": "调试设置",
        "defaults": "恢复默认",
        "defense": "防御力",
        "details": "详情",
        "dps_by_ability": "天赋DPS（平均）",
        "em": "元素精通",
        "er": "元素充能效率",
        "error_copying_not": "复制失败 :( 不确定为什么出问题了",
        "error_encountered": "发生错误：",
        "git_hash": "git哈希：",
        "hp": "生命值",
        "level": "等级",
        "link_is_permanent": "链接是永久的。",
        "link_post": "可在此处查看结果：",
        "link_pre": "上传成功。",
        "link_will_expire": "链接将会在7天后过期。",
        "load_this_configuration": "在进阶模式加载此配置",
        "log_options": "日志选项",
        "make_link_permanent": "使链接永久化？",
        "note_that_by": "请注意，默认情况下分享链接仅在7天内有效。这样做是为了将服务器存储使用率保持在合理的水平。贡献者和Ko-Fi支持者可以输入私钥以使他们的链接永久化。",
        "particles_count": "元素晶球数",
        "please_note_that": "请注意，这并不是试图让人们捐款。这是一种控制共享数据量的简单方法，同时对那些在经济上或通过贡献支持该项目的人表示感谢。如果你确实需要一个永久链接并且你没有访问密钥，只需跳到我们的Discord并请别人为你代做。",
        "reactions_triggered": "触发的反应",
        "refinement": "精炼",
        "sec_post": "秒的战斗（",
        "sec_pre": "模拟了",
        "send_to_simulator": "发送到模拟器",
        "share": "分享",
        "substats": "副属性",
        "summary": "摘要",
        "target": "目标",
        "this_will_overwrite": "这将覆盖你可能拥有的任何现有配置。你确定要继续吗？",
        "time_post": "秒运行）。",
        "time_pre": "次迭代使用了",
        "unknown": "未知",
        "upload": "上传",
        "upload_failed": "上传失败"
    },
    "viewerdashboard": {
        "drag_n_drop": "拖动gcsim的gzip-JSON文件到此处，或者点击以选择",
        "drop_the_file": "将文件拖动到此处…",
        "error_retrieving_specified": "检索指定URL失败",
        "or_select_from": "或者选择之前打开过的文件（页面重载时将会被删除）：",
        "upload_a_file": "上传文件",
        "url_does_not": "无效的gzip-JSON文件URL"
    }
}