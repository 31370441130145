{
    "advanced": {
        "action_list": "Lista de acciones",
        "helpers": "Asistentes",
        "hide": "Ocultar",
        "loading_workers": "Cargando trabajadores",
        "run": "Correr",
        "show": "Mostrar",
        "sim_options": "Opciones de simulación",
        "substat_helper": "Asistente de substats",
        "workers_available": "Trabajadores disponibles: "
    },
    "character": {
        "artifact_stats": "Atributos de artefactos",
        "c_post": "",
        "c_pre": "C",
        "lvl": "Nivel",
        "talents": "Talentos"
    },
    "characteredit": {
        "add_set_bonus": "Agregar bono del conjunto",
        "anemo": "Anemo%",
        "artifact_stats_main": "Atributos de artifactos (Principal + Sub)",
        "ascension": "Ascensión",
        "atk_atk": "Atq/Atq%",
        "attack": "Ataque",
        "burst": "Habilidad definitiva",
        "cd": "Daño crítico",
        "change": "Cambiar",
        "character": "Personaje",
        "cons": "Constelaciones",
        "cr": "Prob. crítico",
        "cryo": "Cryo%",
        "def_def": "Def/Def%",
        "electro": "Electro%",
        "em": "Maestría elemental",
        "er": "Recarga de energía",
        "geo": "Geo%",
        "heal": "Curación%",
        "hp_hp": "Vida/Vida%",
        "hydro": "Hydro%",
        "level": "Nivel",
        "phy": "Físico%",
        "pyro": "Pyro%",
        "refine": "Refinamiento",
        "set_bonus": "Bono de conjunto:",
        "skill": "Habilidad elemental",
        "weapons_and_artifacts": "Armas y artefactos"
    },
    "components": {
        "close": "Cerrar",
        "currently_loaded_workers": "Trabajadores cargados actualmente: ",
        "no_characters": "Sin personajes",
        "result_post": "ms con DPS promedio: ",
        "result_pre": "Simulación completada en ",
        "running_simulation": "Corriendo simulación:",
        "see_results_in": "Ver Resultados En El Visor",
        "set": "Colocar",
        "simulation_exited_with": "Simulación salió con error: ",
        "workers_post": " trabajadores",
        "workers_pre": "Corriendo simulador con "
    },
    "dash": {
        "about": "Acerca de",
        "advanced_mode": "Modo avanzado",
        "contribute": "Contribuye",
        "desktop_tool": "Versión de escritorio",
        "documentation": "Documentación",
        "is_a_team": "es un simulador de DPS del equipo, para Genshin Impact. Empieza seleccionando una de las siguientes opciones!",
        "simulator": "Simulador",
        "teams_db": "Base de datos de equipos",
        "viewer": "Visor"
    },
    "db": {
        "author": "Autor: ",
        "average_dps_per": "DPS promedio por objetivo: ",
        "c_post": "",
        "c_pre": "C",
        "cancel": "Cancelar",
        "character": "Personaje",
        "continue": "Continuar",
        "description": "Descripción:",
        "details": "Detalles",
        "er": "ER: ",
        "error_loading_database": "Error cargando base de datos. No se encontraron datos",
        "filters": "Filtros:",
        "hash": "Hash: ",
        "load_in_simulator": "Cargar en simulador",
        "loading": "Cargando ...",
        "number_of_targets": "Numero de objetivos: ",
        "r": " R",
        "show_in_viewer": "Mostrar en el Visor",
        "total_dps": "DPS total: ",
        "type_to_search": "Escriba para buscar...",
        "weapon": "Arma"
    },
    "footer": {
        "gcsim_is_not": "gcsim no está afiliado con mihoyo. Genshin Impact, el contenido del juego y sus materiales son marcas registradas y derechos de autor de miHoYo."
    },
    "importer": {
        "import_success": "Datos de GOOD importados exitosamente"
    },
    "links": {
        "error_loading_database": "Error cargando base de datos. No se encontraron datos"
    },
    "nav": {
        "about": "Acerca",
        "advanced": "Avanzado",
        "chinese": "中文",
        "english": "English",
        "japanese": "日本語",
        "spanish": "Español",
        "russian": "Русский",
        "german": "Deutsch",
        "language": "Idioma:",
        "simulator": "Simulador",
        "teams_db": "Base de datos de equipos",
        "viewer": "Visor"
    },
    "sim": {
        "loading_simulator_please": "Cargando simulador. Por favor espere"
    },
    "simple": {
        "action_list": "Lista de acciones",
        "add_character": "Agregar personaje",
        "discord_post": " si necesitas algunas ideas.",
        "discord_pre": "Ingresa la lista de acciones que deseas usar en la caja de texto debajo. Tenemos una vasta colección de lista de acciones compartidas por usuarios en nuestro ",
        "documentation": "documentación",
        "documentation_post": " que incluye un simple tutorial en como escribir listas secuenciales (previamente conocidas como modo cálculo).",
        "documentation_pre": "Alternativamente, checa nuestra ",
        "done": "Listo",
        "exit_edit": "Salir de edición",
        "give_me_back": "Regresame mis tips!",
        "hide": "Ocultar",
        "hide_all_tips": "Ocultar todos los tips",
        "loading_workers": "Cargando trabajadores",
        "run": "Correr",
        "show": "Mostrar",
        "sim_options": "Opciones de simulación",
        "start_by_adding": "Empieza por agregar miembros de equipo",
        "team": "Equipo",
        "video": "video",
        "video_post": " como guía inicial.",
        "video_pre": "Empieza por agregar miembros de equipo. checa este ",
        "workers_available": "Trabajadores disponibles: "
    },
    "src": {
        "this_page_is": "Esta página no está implementada aún. Manténganse al tanto!"
    },
    "viewer": {
        "ability_usage_count": "Recuento de uso de habilidad elemental (Promedio)",
        "api_key_supporters": "Llave API (Solo para apoyadores)",
        "attack": "Ataque",
        "average_character_field": "Tiempo de campo promedio por personaje",
        "average_dps_by": "DPS promedio por personaje",
        "avg_dps": "DPS promedio",
        "built_on": ", creado en: ",
        "cancel": "Cancelar",
        "cd": "CD",
        "clear": "Limpiar",
        "close": "Cerrar",
        "combined": "Combinado",
        "config": "Config",
        "continue": "Continuar",
        "copied_to_clipboard": "Copiado al portapapeles",
        "copy": "Copiar",
        "copy_link": "Copiar Link",
        "cr": "CR",
        "create_a_shareable": "Crea un link compartible",
        "damage_dealt_over": "Daño inflingido a lo largo del tiempo",
        "debug": "Depurar",
        "debug_settings": "Opciones de depuración",
        "defaults": "Por defecto",
        "defense": "Defensa",
        "details": "Detalles",
        "dps_by_ability": "DPS por habilidad (Promedio)",
        "em": "Maestría elemental",
        "er": "Recarga de energía",
        "error_copying_not": "Error copiando :( No estoy seguro que fue mal, échale la culpa a SRL",
        "error_encountered": "error encontrado: ",
        "git_hash": "git hash: ",
        "hp": "Vida",
        "level": "Nivel",
        "link_is_permanent": "Link es permanente. ",
        "link_post": "Resultados pueden verse en: ",
        "link_pre": "Subida exitosa. ",
        "link_will_expire": "Link va a expirar en 7 días. ",
        "load_this_configuration": "Carga esta configuración en modo avanzado.",
        "log_options": "Opciones de registro",
        "make_link_permanent": "Hacer el link permanente?",
        "note_that_by": "Tenga en cuenta que por defecto los links compartibles solo son válidos por 7 días. Esto se hace en orden para mantener el almacenamiento del servidor dentro de un nivel razonable. Contribuidores y apoyadores Ko-Fi pueden ingresar una llave privada para hacer sus links permanentes.",
        "particles_count": "Recuento de partículas",
        "please_note_that": "Por favor tenga en cuenta que esto no es un intento para hacer que las personas nos donen dinero. Esta es una simple manera de controlar la cantidad de datos que son compartidos y almacenados, mientras les damos un pequeño agradecimiento a aquellos que han apoyado este proyecto de forma financiera o por contribuciones. Si necesitas un link permanente y no tienes una llave de acceso, simplemente ven a nuestro Discord y preguntale a alguien para que lo haga por ti.",
        "reactions_triggered": "Reacciones desencadenadas",
        "refinement": "Refinamiento",
        "sec_post": "s de combate (",
        "sec_pre": "Simulados ",
        "send_to_simulator": "Mandar a simulador",
        "share": "Compartir",
        "substats": "Substats",
        "summary": "Resumen",
        "target": "Objetivo",
        "this_will_overwrite": "Esto sobreescribirá cualquier configuración existente que tengas. ¿Estás seguro que deseas continuar?",
        "time_post": "s para correr).",
        "time_pre": " iteraciones tomaron ",
        "unknown": "desconocido",
        "upload": "Subir",
        "upload_failed": "subida fallida"
    },
    "viewerdashboard": {
        "drag_n_drop": "Arrastra y suelta un archivo JSON gzip (.gz) de gcsim aquí, o click para seleccionar",
        "drop_the_file": "Suelte el archivo aquí ...",
        "error_retrieving_specified": "error recuperando la URL específica",
        "or_select_from": "O selecciona de los siguientes archivos abiertos previamente (se borrarán cuando la pagina recargue):",
        "upload_a_file": "Subir un archivo",
        "url_does_not": "URL no contiene un archivo JSON gzip válido (.gz)"
    }
}