{
  "advanced": {
    "action_list": "Список действий",
    "helpers": "Помощники",
    "hide": "Скрыть",
    "loading_workers": "Загружаю обработчиков",
    "run": "Запустить",
    "show": "Показать",
    "sim_options": "Параметры симуляции",
    "substat_helper": "Помощник сабстатов",
    "workers_available": "Доступно обработчиков: "
  },
  "character": {
    "artifact_stats": "Характеристики артефактов",
    "c_post": "",
    "c_pre": "C",
    "lvl": "Уровень",
    "talents": "Таланты"
  },
  "character_names": {
    "aether": "Итэр",
    "lumine": "Люмин",
    "albedo": "Альбедо",
    "aloy": "Элой",
    "amber": "Эмбер",
    "barbara": "Барбара",
    "beidou": "Бэй Доу",
    "bennett": "Беннет",
    "chongyun": "Чун Юнь",
    "diluc": "Дилюк",
    "diona": "Диона",
    "eula": "Эола",
    "fischl": "Фишль",
    "ganyu": "Гань Юй",
    "hutao": "Ху Тао",
    "jean": "Джинн",
    "kazuha": "Кадзуха",
    "kaeya": "Кэйа",
    "ayaka": "Аяка",
    "keqing": "Кэ Цин",
    "klee": "Кли",
    "sara": "Сара",
    "lisa": "Лиза",
    "mona": "Мона",
    "ningguang": "Нин Гуан",
    "noelle": "Ноэлль",
    "qiqi": "Ци Ци",
    "raiden": "Райдэн",
    "razor": "Рэйзор",
    "rosaria": "Розария",
    "kokomi": "Кокоми",
    "sayu": "Саю",
    "sucrose": "Сахароза",
    "tartaglia": "Тарталья",
    "thoma": "Тома",
    "venti": "Венти",
    "xiangling": "Сян Лин",
    "xiao": "Сяо",
    "xingqiu": "Син Цю",
    "xinyan": "Синь Янь",
    "yanfei": "Янь Фэй",
    "yoimiya": "Ёимия",
    "zhongli": "Чжун Ли",
    "gorou": "Горо",
    "itto": "Итто",
    "shenhe": "Шэнь Хэ",
    "yunjin": "Юнь Цзинь",
    "yaemiko": "Яэ Мико",
    "ayato": "Аято"
  },
  "components": {
    "close": "Закрыть",
    "currently_loaded_workers": "Обработчиков загружено: ",
    "no_characters": "Нет персонажей",
    "result_post": "мс со средним DPS: ",
    "result_pre": "Симуляция завершена за ",
    "running_simulation": "Симуляция запущена:",
    "see_results_in": "Посмотреть результат в \"Просмотр\"",
    "set": "Применить",
    "simulation_exited_with": "Симуляция прекращена с ошибкой: ",
    "workers_post": " обработчиками",
    "workers_pre": "Запускаю симуляцию с "
  },
  "dash": {
    "about": "О нас",
    "advanced_mode": "Продвинутый режим",
    "contribute": "Помочь нам",
    "desktop_tool": "Приложение на ПК",
    "documentation": "Документация",
    "is_a_team": "это симулятор DPS отряда для Genshin Impact. Начните с выбора одного из вариантов!",
    "simulator": "Симулятор",
    "teams_db": "База данных команд",
    "viewer": "Просмотр"
  },
  "db": {
    "author": "Автор: ",
    "average_dps_per": "Средний DPS на одну цель: ",
    "c_post": "",
    "c_pre": "C",
    "cancel": "Отмена",
    "character": "Персонаж",
    "continue": "Продолжить",
    "description": "Описание:",
    "details": "Детали",
    "er": "ER: ",
    "error_loading_database": "Ошибка загрузки базы данных. Данные не найдены",
    "filters": "Фильтры:",
    "hash": "Хэш: ",
    "load_in_simulator": "Открыть в Симуляторе",
    "loading": "Загрузка...",
    "number_of_targets": "Количество целей: ",
    "r": " R",
    "show_in_viewer": "Открыть в Просмотре",
    "total_dps": "Общий DPS: ",
    "type_to_search": "Введите для поиска...",
    "weapon": "Оружие"
  },
  "elements": {
    "none": "Пусто",
    "anemo": "Анемо",
    "geo": "Гео",
    "pyro": "Пиро",
    "cryo": "Крио",
    "hydro": "Гидро",
    "electro": "Электро",
    "dendro": "Дендро"
  },
  "footer": {
    "gcsim_is_not": "gcsim не связан с miHoYo. Genshin Impact, игровой контент и материалы являются товарными знаками и авторскими правами компании miHoYo."
  },
  "importer": {
    "import_success": "Отлично, ваши данные успешно загружены"
  },
  "links": {
    "error_loading_database": "Ошибка загрузки базы данных. Данные не найдены"
  },
  "nav": {
    "about": "О нас",
    "advanced": "Продвинутый режим",
    "chinese": "中文",
    "japanese": "日本語",
    "english": "English",
    "spanish": "Español",
    "russian": "Русский",
    "german": "Deutsch",
    "language": "Язык:",
    "simulator": "Симулятор",
    "teams_db": "База данных команд",
    "viewer": "Просмотр"
  },
  "sim": {
    "loading_simulator_please": "Симулятор загружается. Пожалуйста подождите"
  },
  "simple": {
    "action_list": "Список действий",
    "add_character": "Добавить персонажа",
    "discord_post": " Если у вас есть идеи.",
    "discord_pre": "Введите список действий, которые вы хотите использовать, в текстовое поле ниже. У нас есть обширная коллекция действий, представленных пользователями на нашем ",
    "documentation": "документацию",
    "documentation_post": " которая включает в себя простое руководство по написанию скрипта (ранее известный как режим calc).",
    "documentation_pre": "Можете так-же заглянуть в нашу ",
    "done": "Готово",
    "exit_edit": "Выйти из редактора",
    "give_me_back": "Вернуть подсказки!",
    "hide": "Скрыть",
    "hide_all_tips": "Скрыть все подсказки",
    "loading_workers": "Обработчиков загружено",
    "run": "Запустить",
    "show": "Показать",
    "sim_options": "Параметры симуляции",
    "start_by_adding": "Начните с добавления персонажей в отряд",
    "team": "Отряд",
    "video": "Видео",
    "video_post": " гайд, который поможет вам начать.",
    "video_pre": "Начните с добавления персонажей в отряд. ",
    "workers_available": "Обработчиков доступно: "
  },
  "src": {
    "this_page_is": "Эта страница еще не доступна. Оставайтесь с нами!"
  },
  "viewer": {
    "ability_usage_count": "Количество исользованных способностей (Среднее)",
    "api_key_supporters": "API ключ (только для спонсоров)",
    "attack": "Атака",
    "average_character_field": "Среднее время персонажа на поле",
    "average_dps_by": "Средней DPS на персонажа",
    "avg_dps": "Средний DPS",
    "built_on": ", Время: ",
    "cancel": "Отмена",
    "cd": "CD",
    "clear": "Очистить",
    "close": "Закрыть",
    "combined": "Соединено",
    "config": "Конфигурация",
    "continue": "Продолжить",
    "copied_to_clipboard": "Скопировано в буфер обмена",
    "copy": "Скопировать",
    "copy_link": "Скопировать ссылку",
    "cr": "CR",
    "create_a_shareable": "Создать общедоступную ссылку",
    "damage_dealt_over": "Нанесённый урон за всё время",
    "debug": "Отладка",
    "debug_settings": "Настройки отладки",
    "defaults": "По-умолчанию",
    "defense": "Защита",
    "details": "Детали",
    "dps_by_ability": "DPS на способность (Средний)",
    "em": "EM",
    "er": "ER",
    "error_copying_not": "Ошибка копирования :(, что-то пошло не так",
    "error_encountered": "обнаруженная ошибка: ",
    "git_hash": "git hash: ",
    "hp": "HP",
    "level": "Уровень",
    "link_is_permanent": "Данная ссылка постоянная. ",
    "link_post": "Результат здесь: ",
    "link_pre": "Успешно загружено. ",
    "link_will_expire": "Ссылка пропадёт через 7 дней. ",
    "load_this_configuration": "Загрузите эту конфигурацию в Расширенном режиме.",
    "log_options": "Настройки логирования",
    "make_link_permanent": "Сделать ссылку постоянной?",
    "note_that_by": "Учтите, что вы можете поделиться стандартной ссылкой в течении 7 дней. Это сделано для того, чтобы не нагружать сервер. Помощники или люди, поддерживающие в Ko-Fi, могут ввести приватный ключ, чтобы сделать свои ссылки постоянными.",
    "particles_count": "Количество частиц",
    "please_note_that": "Пожалуйста, обратите внимание, что мы не заставляем людей жертвовать деньги. Это просто способ ограничить объем данных. Мы благодарим тех, кто как-либо поддержал этот проект финансово, либо внес свой вклад в его развитие. Если вам действительно необходима постоянная ссылка, но при этом у вас нет ключа доступа, просто перейдите в наш Discord и попросите кого-нибудь сделать её для вас.",
    "reactions_triggered": "Реакций сработано",
    "refinement": "Пробуждения",
    "sec_post": "сек в бою (",
    "sec_pre": "Сэмулировано ",
    "send_to_simulator": "Отправить в Симуляцию",
    "share": "Поделиться",
    "substats": "Дополнительные харрактеристики",
    "summary": "Итог",
    "target": "Цель",
    "this_will_overwrite": "Это приведет к перезаписи текущей конфигурации. Вы уверены, что хотите продолжить?",
    "time_post": "сек на обработку).",
    "time_pre": " итерации заняли ",
    "unknown": "неизвестно",
    "upload": "Загрузить",
    "upload_failed": "Неудачная загрузка"
  },
  "viewerdashboard": {
    "drag_n_drop": "Перетащите сюда сжатый JSON (.gz) файл из gcsim или нажмите для выбора.",
    "drop_the_file": "Перетащите файл сюда...",
    "error_retrieving_specified": "ошибка при получении указанного URL-адреса",
    "or_select_from": "Или выберите один из ранее открытых файлов (удаляются при перезагрузке страницы):",
    "upload_a_file": "Загрузить файл",
    "url_does_not": "URL не содержит допустимый gzipped JSON файл"
  }
}